enum EventType {
	Changed,
	Clicked,
	Blur
}

export interface ISelectedField {
	setClicked(namespace: string | undefined, fieldName: string): void;
	setBlur(namespace: string | undefined, fieldName: string): void;
	setChanged(namespace: string | undefined, fieldName: string): void;
	addCallback(
		c: (
			eventType: EventType,
			namespace: string | undefined,
			fieldName: string
		) => void
	): void;
}

export interface IAutomaticObjectPriceCalculation {
	isObjectPriceCalculation(): boolean;
	reset(): void;
}

export const SelectedField = ((): ISelectedField => {
	const callbacks: {
		(
			eventType: EventType,
			namespace: string | undefined,
			fieldName: string
		): void;
	}[] = [];

	return {
		setClicked: (namespace: string | undefined, fieldName: string) =>
			callbacks.forEach(callback =>
				callback(EventType.Clicked, namespace, fieldName)
			),

		setBlur: (namespace: string | undefined, fieldName: string) =>
			callbacks.forEach(callback =>
				callback(EventType.Blur, namespace, fieldName)
			),

		setChanged: (namespace: string | undefined, fieldName: string) =>
			callbacks.forEach(callback =>
				callback(EventType.Changed, namespace, fieldName)
			),

		addCallback: (
			c: (
				eventType: EventType,
				namespace: string | undefined,
				fieldName: string
			) => void
		) => callbacks.push(c)
	};
})();

export const AutomaticObjectPriceCalculation = ((): IAutomaticObjectPriceCalculation => {
	const state: { isObjectPriceCalculation: boolean } = {
		isObjectPriceCalculation: false
	};

	SelectedField.addCallback(
		(
			eventType: EventType,
			namespace: string | undefined,
			fieldName: string
		) => {
			if (eventType === EventType.Changed && namespace === "Calculator") {
				switch (fieldName) {
					case "ObjectPriceExVat":
					case "ObjectPriceIncVat":
						state.isObjectPriceCalculation = false;
						break;
					case "DesiredTermAmount":
					case "GrossDesiredTermAmount":
						state.isObjectPriceCalculation = true;
						break;
				}
			}
		}
	);

	return {
		isObjectPriceCalculation: (): boolean => {
			return state.isObjectPriceCalculation;
		},
		reset: (): void => {
			state.isObjectPriceCalculation = false;
		}
	};
})();
