import { apiResourceUrl } from "@ploy-lib/core";
import { EndpointExtraOptions } from "@rest-hooks/rest";
import { ID } from "../types";
import { ApplicationResource } from "./ApplicationResource";
import { BaseResource } from "./BaseResource";

export enum AMLRisk {
	NoContact = "NoContact",
	NotSet = "NotSet",
	Low = "Low",
	Medium = "Medium",
	High = "High"
}

export enum CustomerAMLRole {
	NotSet = "NotSet",
	Customer = "Customer",
	CoSigner = "CoSigner",
	BeneficialOwner = "BeneficialOwner",
	Dagligleder = "Dagligleder",
	Nestleder = "Nestleder",
	Obervatør = "Observatør",
	Styremedlem = "Styremedlem",
	Styretsleder = "Styretsleder",
	Varamedlem = "Varamedlem",
	Kontaktperson = "Kontaktperson",
	Fullmaktshaver = "Fullmaktshaver",
	Unknown = "Unknown"
}

export interface RiskResult {
	name: string;
	result: AMLRisk;
	riskScore: number;
	value?: string;
}

export interface CustomerAMLResult {
	firstName: string;
	lastName: string;
	role: CustomerAMLRole;
	riskResults: RiskResult[];
}

interface PolicyRulesResult {
	id: ID;
	value: number;
}

interface RcOverrideResult {
	id: ID;
	userFullName: string;
	previousTotalRisk: number;
	newTotalRisk: number;
	comment: string;
	timestamp: string;
}

export class ApplicationAMLRisksResource extends BaseResource {
	readonly id?: ID;
	readonly applicationNumber?: string;
	readonly totalRisk: AMLRisk = AMLRisk.NotSet;
	readonly overrideRisk?: AMLRisk;
	readonly customerAMLResults: CustomerAMLResult[] = [];
	readonly riskResults: RiskResult[] = [];
	readonly canOverride: Boolean;
	readonly showRiskScoreValue: Boolean;
	readonly canOverrideBeneficialOwner: Boolean;

	pk() {
		return this.id?.toString();
	}

	static url(
		allParams: Readonly<Record<string, string | number>> = {}
	): string {
		const { applicationNumber, customerId } = allParams;
		if (applicationNumber !== undefined) {
			if (customerId !== undefined) {
				return `${ApplicationResource.urlRoot}/${applicationNumber}/aml-risk?customerId=${customerId}`;
			} else {
				return `${ApplicationResource.urlRoot}/${applicationNumber}/aml-risk`;
			}
		} else {
			throw new Error("ApplicationAMLRisksResource requires applicationNumber");
		}
	}

	// static indexes = ["applicationNumber"];

	static urlTemplates = [
		`${ApplicationResource.urlRoot}/{applicationNumber}/aml-risk`
	];

	static urlRoot = apiResourceUrl("aml-risks");
}

export class ApplicationAMLRiskHistoryResource extends BaseResource {
	readonly id?: ID;
	readonly userFullName?: string;
	readonly risk: AMLRisk = AMLRisk.NotSet;
	readonly comment: string = "";
	readonly timestamp: string = "";

	pk() {
		return this.id?.toString();
	}

	static getEndpointExtra(): EndpointExtraOptions {
		return {
			...super.getEndpointExtra(),
			pollFrequency: 60 * 1000,
			dataExpiryLength: 30 * 1000
		};
	}

	static urlTemplates = ApplicationAMLRisksResource.urlTemplates.map(
		r => `${r}/history`
	);

	static urlRoot = `${ApplicationAMLRisksResource.urlRoot}/{amlRiskId}/history`;
}

export class ApplicationAMLRiskCustomerResource extends BaseResource {
	readonly id: ID;
	readonly customerName?: string;
	readonly customerCompanyNumber?: string;
	readonly customerNumber?: string;
	readonly isPrivateCustomer?: boolean;

	pk() {
		return this.id?.toString();
	}

	static getEndpointExtra(): EndpointExtraOptions {
		return {
			...super.getEndpointExtra(),
			pollFrequency: 60 * 1000,
			dataExpiryLength: 30 * 1000
		};
	}

	static urlTemplates = ApplicationAMLRisksResource.urlTemplates.map(
		r => `${r}/customer`
	);

	static urlRoot = `${ApplicationAMLRisksResource.urlRoot}/{applicationNumber}/customer`;
}

export class ApplicationAMLRiskCommentResource extends BaseResource {
	readonly id?: ID;
	readonly userFullName?: string;
	readonly comment: string = "";
	readonly timestamp: string = "";

	pk() {
		return this.id?.toString();
	}

	static getEndpointExtra(): EndpointExtraOptions {
		return {
			...super.getEndpointExtra(),
			pollFrequency: 60 * 1000,
			dataExpiryLength: 30 * 1000
		};
	}

	static urlTemplates = ApplicationAMLRisksResource.urlTemplates.map(
		r => `${r}/comment`
	);

	static urlRoot = `${ApplicationAMLRisksResource.urlRoot}/{applicationNumber}/comment`;
}

export class ApplicationAMLRiskClassificationResultResource extends BaseResource {
	readonly id?: ID;
	readonly value: number;

	pk() {
		return this.id?.toString();
	}

	static urlTemplates = ApplicationAMLRisksResource.urlTemplates.map(
		r => `${r}/policy-rules/result`
	);

	static urlRoot = `${ApplicationAMLRisksResource.urlRoot}/{amlRiskId}/policy-rules/result`;

	static fromJS(data: any): any {
		if (!data || data.id === undefined) {
			const emptyValue: PolicyRulesResult = { id: -1, value: -1 };
			return super.fromJS({ ...new this(), ...emptyValue });
		}
		return super.fromJS(data);
	}
}

export class ApplicationAMLRiskPolicyRulesResource extends BaseResource {
	readonly id?: ID;
	readonly name: string;
	readonly description: string;
	readonly status: string;
	readonly message: string;
	readonly value: number;

	pk() {
		return this.id?.toString();
	}

	static urlTemplates = ApplicationAMLRisksResource.urlTemplates.map(
		r => `${r}/policy-rules`
	);

	static urlRoot = `${ApplicationAMLRisksResource.urlRoot}/{amlRiskId}/policy-rules`;
}

export class ApplicationAMLOverrideResource extends BaseResource {
	readonly id: ID;
	readonly userFullName: string;
	readonly previousTotalRisk: number;
	readonly newTotalRisk: number;
	readonly comment: string;
	readonly timestamp: string;

	pk() {
		return this.id?.toString();
	}

	static urlTemplates = ApplicationAMLRisksResource.urlTemplates.map(
		r => `${r}/rc-override`
	);

	static urlRoot = `${ApplicationAMLRisksResource.urlRoot}/{amlRiskId}/rc-override`;

	static fromJS(data: any): any {
		if (!data || data.id === undefined) {
			const emptyValue: RcOverrideResult = {
				id: -1,
				userFullName: "",
				previousTotalRisk: -1,
				newTotalRisk: -1,
				comment: "",
				timestamp: ""
			};
			return super.fromJS({ ...new this(), ...emptyValue });
		}
		return super.fromJS(data);
	}
}

export class ApplicationAMLOverrideHistoryResource extends BaseResource {
	readonly id: ID;
	readonly userFullName: string;
	readonly previousTotalRisk: number;
	readonly newTotalRisk: number;
	readonly comment: string;
	readonly timestamp: string;

	pk() {
		return this.id?.toString();
	}

	static urlTemplates = ApplicationAMLRisksResource.urlTemplates.map(
		r => `${r}/rc-override/all`
	);

	static urlRoot = `${ApplicationAMLRisksResource.urlRoot}/{amlRiskId}/rc-override/all`;
}
