import { ID } from "../types";
import { apiResourceUrl } from "@ploy-lib/core";
import { BaseResource } from "./BaseResource";
import { ApplicationDebt, ApplicationDebtSource } from "@ploy-lib/types";
import {
	SimpleResource,
	SchemaList,
	AbstractInstanceType
} from "@rest-hooks/rest";

export interface ApplicationDebtParams {
	applicationNumber: string;
}

export class ApplicationDebtResource
	extends BaseResource
	implements ApplicationDebt
{
	readonly termAmount: number = 0;
	readonly refinance: boolean = false;
	readonly nominalInterest?: number;
	readonly maturity?: number;
	readonly applicationDebtId?: ID;
	readonly amount: number = 0;
	readonly originalAmount: number = 0;
	readonly comment: string = "";
	readonly creditLimit?: number;
	readonly customerId?: number;
	readonly lender: string = "";
	readonly loanNumber: string = "";
	readonly loanType: string = "";
	readonly refinanceAccount: string = "";
	readonly refinanceAmount: number = 0;
	readonly source: ApplicationDebtSource = ApplicationDebtSource.NotSet;

	pk() {
		return `${this.applicationDebtId}`;
	}

	/** Shape to update a list of existing entity (put) */
	static updateList<T extends typeof SimpleResource>(this: T) {
		return this.update().extend({
			schema: [this] as SchemaList<AbstractInstanceType<T>>
		});
	}

	// 		Enable optimistic updates?
	//
	// static getEndpointExtra(): FetchOptions {
	// 	return {
	// 		...super.getEndpointExtra(),
	// 		optimisticUpdate: (params, body) => body
	// 	};
	// }

	static urlRoot = apiResourceUrl("applications/{applicationNumber}/debts");
}
