import { useEffect } from "react";

const OldDealerRedirect = () => {
	useEffect(() => {
		window.location.href = "/Pages/StartPage/StartPage.aspx";
	}, []);

	return null;
};

export default OldDealerRedirect;
