import React, { useEffect, useRef, useState } from "react";

import {
	Grid,
	Divider,
	Typography,
	Button,
	ThemeProvider,
	Hidden,
	Paper
} from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { StickyFooter } from "../../../panel";

import { TemplatePageProps, LayoutPage } from "../../../types";
import { addRegisteredLayout, PanelSettings } from "../layoutDescriptions";
import { Panel } from "../../../panel";
import { DirwebStepper } from "./DirwebStepper";
import { useStyles, actionsTheme, mainTheme } from "./styles";
import { TemplatePanel } from "@ploy-lib/types";
import { usePageState } from "../../../PageContext";
import { FormattedMessage } from "react-intl";
import clsx from "clsx";
import { useTenant } from "@ploy-ui/tenants";

export type DirwebPanels = {
	main: TemplatePanel;
	actions: TemplatePanel;
	stickyFooter: TemplatePanel;
	stickyFooterExpanded: TemplatePanel;
};
export type DirwebPanelKeys = keyof DirwebPanels;
export type DirwebPage = LayoutPage<DirwebPanelKeys, DirwebPanels>;

export interface DirwebStylesProps {
	stickyFooterHeight?: number;
	dirWebPaddingTop?: number;
}

const mainPanelSettings: PanelSettings = {
	editableOptions: {
		title: false,
		elevation: true,
		square: true
	}
};
const panelSettings: Record<DirwebPanelKeys, PanelSettings> = {
	main: mainPanelSettings,
	actions: {},
	stickyFooter: mainPanelSettings,
	stickyFooterExpanded: mainPanelSettings
};

addRegisteredLayout({
	name: "Dirweb",
	displayName: "Dirweb",
	panels: panelSettings,
	pageDescription: true
});

export interface DirwebProps extends TemplatePageProps<DirwebPage> {}

export const Dirweb = (props: DirwebProps) => {
	const { description, panels, stepper } = props;
	const { main, actions, stickyFooter, stickyFooterExpanded } = panels;
	const { step, prev } = usePageState();

	const footerRef = useRef<HTMLInputElement>(null);
	const [footerHeight, setFooterHeight] = useState(70);

	const updateFooterHeight = () => {
		if (footerRef?.current) {
			// Is zero if footer is hidden. Typically when loading form
			if (footerRef.current.clientHeight === 0) {
				setTimeout(() => updateFooterHeight(), 500);
				return;
			}
			const newHeight =
				footerRef.current.clientHeight < 70
					? 70
					: footerRef.current.clientHeight;
			if (footerHeight !== newHeight) setFooterHeight(newHeight);
		}
	};

	useEffect(() => {
		updateFooterHeight();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const tenantConfig = useTenant();
	const dirWebPaddingTop = tenantConfig?.tenant?.settings?.dirWebPaddingTop;

	const stylesProps = {
		stickyFooterHeight: footerHeight,
		dirWebPaddingTop: dirWebPaddingTop
	} as DirwebStylesProps;

	const dirwebClasses = useStyles(stylesProps);

	const myActions: TemplatePanel = {
		...actions,
		sections: [
			{
				formTemplateSectionId: "__myaction",
				fields:
					actions?.sections?.[0]?.fields?.filter(
						f => f.role !== "navigate_before"
					) ?? []
			}
		]
	};

	let leftComponent: JSX.Element | null = null;
	if (step > 0 || props.pageTitle || description)
		leftComponent = (
			<>
				<Grid item xs={12} md={3}>
					{step > 0 && (
						<Button
							className={dirwebClasses.backLink}
							color="primary"
							variant="text"
							onClick={prev}
							startIcon={<ArrowBack />}
						>
							<FormattedMessage
								id="dirweb.step-summary.navigate-back-button"
								defaultMessage="Forrige steg"
							/>
						</Button>
					)}

					{props.pageTitle && (
						<Typography
							variant="h5"
							gutterBottom
							className={dirwebClasses.titleText}
						>
							{props.pageTitle}
						</Typography>
					)}

					<Hidden smDown>
						<Typography color="textSecondary" variant="subtitle2">
							{description}
						</Typography>
					</Hidden>
				</Grid>

				<Grid item component={Hidden} smDown implementation="css">
					<Divider orientation="vertical" />
				</Grid>
			</>
		);

	return (
		<>
			<Grid
				container
				className={clsx(dirwebClasses.root)}
				justifyContent="center"
			>
				{!stepper && (
					<Grid item xs={12} md={8}>
						<DirwebStepper step={step} />
					</Grid>
				)}
				{main && (
					<Grid
						component={Paper}
						item
						xs={12}
						md={8}
						className={clsx(dirwebClasses.mainGrid)}
					>
						<Grid container spacing={2} alignItems="stretch">
							{leftComponent}
							<Grid item xs={12} md>
								<ThemeProvider theme={mainTheme}>
									<Panel {...main} transparent disableTabs={true} />
								</ThemeProvider>

								<ThemeProvider theme={actionsTheme}>
									<Panel {...myActions} transparent disableTabs={true} />
								</ThemeProvider>
							</Grid>
						</Grid>
					</Grid>
				)}
			</Grid>
			<StickyFooter
				innerRef={footerRef}
				stickyFooter={stickyFooter}
				stickyFooterExpanded={stickyFooterExpanded}
			/>
		</>
	);
};

Dirweb.displayName = "DployFormDirweb";
