import {
	LimitRedistributionSchema,
	DTDetailTableSchema,
	LimitDetailTableSchema,
	GuarantorTableSchema,
	ApplicationCustomersSchema,
	PropertySchema,
	LienSchema,
	ApplicationDebtSchema,
	LimitedApplicationDebtSchema,
	LimitedRefinancedApplicationDebtSchema
} from "./index";

export const tableSchemas: Record<string, any> = {
	LimitDetailSchema: LimitDetailTableSchema,
	DTTableSchema: DTDetailTableSchema,
	LimitRedistributionSchema: LimitRedistributionSchema,
	GuarantorSchema: GuarantorTableSchema,
	ApplicationCustomersSchema: ApplicationCustomersSchema,
	PropertySchema: PropertySchema,
	LiensSchema: LienSchema,
	ApplicationDebtSchema: ApplicationDebtSchema,
	LimitedApplicationDebtSchema: LimitedApplicationDebtSchema,
	LimitedRefinancedApplicationDebtSchema: LimitedRefinancedApplicationDebtSchema
};
