import { Grid, Typography, ThemeProvider, Paper } from "@material-ui/core";
import { TemplatePageProps, LayoutPage } from "../../../types";
import { addRegisteredLayout, PanelSettings } from "../layoutDescriptions";
import { Panel } from "../../../panel";
import { TemplatePanel } from "@ploy-lib/types";
import clsx from "clsx";
import { useStyles, actionsTheme, mainTheme } from "./styles";

export type VerticalPanels = {
	summaryHeader: TemplatePanel;
	main: TemplatePanel;
	actions: TemplatePanel;
};
export type VerticalPanelKeys = keyof VerticalPanels;
export type VerticalPage = LayoutPage<VerticalPanelKeys, VerticalPanels>;

export interface VerticalStylesProps {}

const mainPanelSettings: PanelSettings = {
	editableOptions: {
		title: false,
		elevation: true,
		square: true
	}
};
const panelSettings: Record<VerticalPanelKeys, PanelSettings> = {
	summaryHeader: {
		defaultOptions: {
			literal: true
		}
	},
	main: mainPanelSettings,
	actions: {}
};

addRegisteredLayout({
	name: "Vertical",
	displayName: "Vertical",
	panels: panelSettings
});

export interface VerticalProps extends TemplatePageProps<VerticalPage> {}

export const Vertical = (props: VerticalProps) => {
	const { panels } = props;
	const { main, actions, summaryHeader } = panels;

	const verticalClasses = useStyles();

	const myActions: TemplatePanel = {
		...actions,
		sections: [
			{
				formTemplateSectionId: "__myaction",
				fields:
					actions?.sections?.[0]?.fields?.filter(
						f => f.role !== "navigate_before"
					) ?? []
			}
		]
	};

	return (
		<>
			<Grid container justifyContent="center">
				{main && (
					<Grid item xs={12} lg={4} md={8}>
						<Paper
							elevation={0}
							square
							className={clsx(verticalClasses.mainGrid)}
						>
							<Grid item xs={12} md>
								{props.pageTitle && (
									<ThemeProvider theme={mainTheme}>
										<Typography
											variant="h1"
											gutterBottom
											className={verticalClasses.titleText}
										>
											{props.pageTitle}
										</Typography>
									</ThemeProvider>
								)}

								{summaryHeader.sections.length > 0 && (
									<ThemeProvider theme={mainTheme}>
										<Panel
											{...summaryHeader}
											transparent
											disableTabs={true}
											fullWidth={true}
										/>
									</ThemeProvider>
								)}

								<ThemeProvider theme={mainTheme}>
									<Panel
										{...main}
										transparent
										disableTabs={true}
										fullWidth={true}
									/>
								</ThemeProvider>

								<ThemeProvider theme={actionsTheme}>
									<Panel
										{...myActions}
										transparent
										disableTabs={true}
										fullWidth={true}
									/>
								</ThemeProvider>
							</Grid>
						</Paper>
					</Grid>
				)}
			</Grid>
		</>
	);
};

Vertical.displayName = "DployFormVertical";
