import { useContext, useEffect } from "react";
import { ThirdPartyScriptsContext } from "@ploy-ui/core";
import { useAppLoad } from "../appLoadContext";
import { useVariableData } from "@ploy-lib/calculation";
import { LoginResource, VulcanDataResource } from "@ploy-lib/rest-resources";
import { CustomerRole } from "@ploy-lib/types";
import { useCache, useResource } from "@rest-hooks/core";
import moment from "moment";
import { usePageState } from "../PageContext";

export const useGTM = () => {
	const appLoadData = useAppLoad();
	const { google } = useContext(ThirdPartyScriptsContext);
	const { isAuthenticated } = useCache(LoginResource.status(), {}) ?? {};
	const vulcanData = useResource(
		VulcanDataResource.detail(),
		isAuthenticated ? {} : null
	);

	const loanAmount = useVariableData<number>("Calculator", "LoanAmount")?.value;

	const GTMData = {
		event: "VirtualPageview",
		product: appLoadData.productName,
		vendor: vulcanData?.vendorName,
		loanAmount: loanAmount
	};

	const pushEvent = (step: number) => {
		let virtualPageViewTitle = "";
		let virtualPageViewURL = "";
		let orderId = "";

		if (appLoadData.vulcanContext === "PostProcess") {
			virtualPageViewURL = `/bekreftelse`;
			virtualPageViewTitle = "Bekreftelse";
			orderId = appLoadData.applicationNumber?.split("-")?.[0] ?? "";
		} else {
			switch (step) {
				case 0:
					virtualPageViewURL = `/finansiering`;
					virtualPageViewTitle = "Finansiering";
					break;
				case 1:
					virtualPageViewURL = `/gjenstand`;
					virtualPageViewTitle = "Gjenstand";
					break;
				case 2:
					virtualPageViewURL = `/kundeinfo`;
					virtualPageViewTitle = "Kundeinfo";
					break;
				case 3:
					virtualPageViewURL = `/oppsummering`;
					virtualPageViewTitle = "Oppsummering";
					break;
				default:
					break;
			}
		}

		const alreadyExistsInHistory: boolean = google
			.getHistory()
			.filter((item: any) => item.event === "VirtualPageview")
			.some((item: any) => item.virtualPageURL === virtualPageViewURL);

		// Avoid virtual pageview duplicates
		if (alreadyExistsInHistory) {
			return;
		}
		google.push({
			...GTMData,
			virtualPageViewURL: virtualPageViewURL,
			virtualPageTitle: virtualPageViewTitle,
			orderId: orderId
		});

		const hotjarUri = `/soknad/steg${step}`;
		//	Call hj() after pushing to dataLayer
		if (window["hj"]) {
			window["hj"]("vpv", hotjarUri);
		}
	};
	return pushEvent;
};

interface GTMExtendedData {
	event?: string;
	checkout_step?: number;
	virtualPageURL?: string;
	virtualPageTitle?: string;
	ecommerce: {
		currency: string;
		value?: number;
		items: {
			item_id?: number;
			item_name?: string;
			affiliation?: string;
			item_brand?: string;
			item_category?: string;
			item_variant?: string;
			price?: number;
			quantity: string;
		}[];
		transaction_id?: string;
		application_date?: string;
		application_status?: string;
	};
}

export const useGTMExtended = () => {
	const appLoadData = useAppLoad();
	const { step, labels } = usePageState();
	const tabName = labels?.[step]?.toLocaleLowerCase() ?? "";

	const { google, settings } = useContext(ThirdPartyScriptsContext);
	const objectCategoryId = useVariableData<string>(
		"Object",
		"CategoryCombined"
	).value;

	const { isAuthenticated } = useCache(LoginResource.status(), {}) ?? {};

	const vulcanData = useResource(
		VulcanDataResource.detail(),
		isAuthenticated ? {} : null
	);

	const loanAmount = useVariableData<number>("Calculator", "LoanAmount")?.value;

	type optionsType = { key: string; value: string };
	const objectCategoriesOptions = useVariableData<optionsType[] | string>(
		"Object",
		"options_CategoryCombined"
	);

	// the new application forms uses a dummy cosigner for the application during its initial state, so we can't check for cosignerid
	const coSignerPhoneMobile =
		useVariableData<optionsType[] | string>("CoSigner", "PhoneMobile")?.value ??
		"";

	if (!settings.loadGoogleTagManager) return (event: string = "") => {};

	//	Matches e.g. '"85|48"' to '{key: "85|48", value: "Campingvogn|Kjøretøy"}' => "Campingvogn":
	const selectedObjectCategory: string | undefined = Array.isArray(
		objectCategoriesOptions?.value
	)
		? objectCategoriesOptions?.value
				?.filter((catCombined: optionsType) => {
					return (
						catCombined["key"].split("|")[0] === objectCategoryId?.split("|")[0]
					);
				})[0]
				?.value.split("|")[0]
		: undefined;

	let virtualPageViewURLPostfix = GTMCodeFromProductName(
		appLoadData.productName ?? ""
	);

	const isCustomer = appLoadData.loggedInCustomerRole === CustomerRole.Customer;
	const isCoSigner = appLoadData.loggedInCustomerRole === CustomerRole.CoSigner;
	const isDirectCustomer = appLoadData.isDirectUser;
	const hasCoSigner = coSignerPhoneMobile !== "";
	let itemVariant = "forhandler_eller_agent";
	if (isCustomer) itemVariant = "hovedsøker";
	else if (isCoSigner) itemVariant = "medsøker";
	else if (isDirectCustomer) itemVariant = "hovedsøker";

	const GTMData: GTMExtendedData = {
		ecommerce: {
			currency: "nok",
			value: loanAmount,
			items: [
				{
					item_id: appLoadData.productId,
					item_name: appLoadData.productName,
					affiliation: vulcanData?.vendorName,
					item_brand: appLoadData.vendorInfo?.salesChannel,
					item_category: selectedObjectCategory,
					item_variant: itemVariant,
					price: loanAmount,
					quantity: "1"
				}
			]
		}
	};

	const pushEvent = (event: string = "", submitData?: any) => {
		if (!event) return;
		let data = GTMData;
		let checkout_step = 0;
		let virtualPageViewURL = "";
		let virtualPageViewTitle = "";
		let status = "sendt til kunde";
		if (isCustomer && hasCoSigner) status = "sendt til medsøker";
		else if (isCustomer || isCoSigner || isDirectCustomer) status = "innsendt";

		if (event === "purchase") {
			checkout_step = 4;
			data.ecommerce.transaction_id =
				submitData?.ApplicationNumber?.split("-")?.[0];
			data.ecommerce.application_date = moment(new Date()).format("YYYY-MM-DD");
			data.ecommerce.application_status = status;
			virtualPageViewURL = `/søknad-sendt${virtualPageViewURLPostfix}`;
			virtualPageViewTitle = "søknad-sendt";
		} else {
			if (event === "begin_checkout") checkout_step = 1;
			else if (event === "add_payment_info") checkout_step = 2;
			else if (event === "add_shipping_info") checkout_step = 3;
			virtualPageViewURL = `/${tabName}${virtualPageViewURLPostfix}`;
			virtualPageViewTitle = `${tabName}`;
		}

		const alreadyExistsInHistory: boolean = google
			.getHistory()
			.some(h => h.event === event);

		// Avoid posting duplicate events
		if (alreadyExistsInHistory || data.ecommerce.value === 0) {
			return;
		}
		google.push({
			...data,
			event: event,
			checkout_step: checkout_step,
			virtualPageURL: virtualPageViewURL,
			virtualPageTitle: virtualPageViewTitle
		});
		//	Call hj() after pushing to dataLayer
		const hotjarUri = `/soknad${virtualPageViewURLPostfix}/steg${step}`;
		if (window["hj"]) {
			window["hj"]("vpv", hotjarUri);
		}
	};
	return pushEvent;
};

export const HandlePageChangeForGTM = ({
	step,
	labelsWithEvents
}: {
	step: number;
	labelsWithEvents: {
		label: string | undefined;
		gtmPageEvent: string | undefined;
	}[];
}) => {
	const appLoadData = useAppLoad();
	const pushEvent = useGTM();
	const pushEventExtended = useGTMExtended();
	const { settings } = useContext(ThirdPartyScriptsContext);

	useEffect(() => {
		if (!settings.loadGoogleTagManager) return;
		if (settings.loadGoogleTagManagerExtended) {
			const pageEvents = labelsWithEvents.map(l => l.gtmPageEvent);
			if (pageEvents.some(l => !!l)) {
				const event = pageEvents[step];
				if (event) pushEventExtended(event);
			} else if (!appLoadData.isDirectUser) {
				// Fallback to default events (dealerweb forms)
				if (step === 0) {
					pushEventExtended("begin_checkout");
				} else if (step === 1) pushEventExtended("add_payment_info");
				else if (step === 2) pushEventExtended("add_shipping_info");
			}
		} else pushEvent(step);
	}, [
		appLoadData.isDirectUser,
		labelsWithEvents,
		pushEvent,
		pushEventExtended,
		settings.loadGoogleTagManager,
		settings.loadGoogleTagManagerExtended,
		step
	]);
	return null;
};

const GTMCodeFromProductName = (productName: string) => {
	return (
		"-" +
		productName
			.toLowerCase()
			.replace(/ /g, "-")
			.replace(/æ/g, "ae")
			.replace(/ø/g, "oe")
			.replace(/å/g, "aa")
	);
};
