import React, { Suspense, useState, CSSProperties } from "react";
import { useFetcher } from "@rest-hooks/core";
import {
	Grid,
	LinearProgress,
	Tooltip,
	Typography,
	makeStyles
} from "@material-ui/core";
import ResetIcon from "@material-ui/icons/SettingsBackupRestore";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import { DployTable } from "../DployTable";
import {
	SigningDocumentResource,
	SigningDocument,
	ID,
	ApplicationSigner
} from "@ploy-lib/rest-resources";
import { FormattedDate, defineMessages, useIntl } from "react-intl";
import { downloadBlob as downloadUtil } from "@ploy-lib/core";
import { useTenant } from "@ploy-ui/tenants";

export interface AppSigningStatusProps {
	applicationNumber: ID;
	documents: SigningDocumentResource[] | null;
	hideSigningActions?: boolean;
	useIndividualSignerStatus: boolean;
}
const messages = defineMessages({
	title: {
		id: "core.application-signing.status.title",
		defaultMessage: "Status"
	},
	read: {
		id: "core.application-signing.status.read",
		defaultMessage: "Read"
	},
	documentGroup: {
		id: "core.application-signing.status.document-group",
		defaultMessage: "Document group"
	},
	partlySigned: {
		id: "core.application-signing.status.partially-signed",
		defaultMessage: "Partially signed"
	},
	signed: {
		id: "core.application-signing.status.signed",
		defaultMessage: "Signed"
	},
	reSign: {
		id: "core.application-signing.status.reset",
		defaultMessage: "Reset"
	},
	download: {
		id: "core.application-signing.status.download",
		defaultMessage: "Download"
	},
	error: {
		id: "core.application-signing.status.error",
		defaultMessage: "There was an error"
	},
	status: {
		id: "core.application-signing.status.status",
		defaultMessage: "Status"
	},
	personsToSign: {
		id: "core.application-signing.status.personsToSign",
		defaultMessage: "PersonsToSign"
	},
	downloadAllDocuments: {
		id: "core.application-signing.status.downloadAllDocuments",
		defaultMessage: "Download all documents"
	},
	notRead: {
		id: "core.application-signing.status.notRead",
		defaultMessage: "Not read"
	},
	notSigned: {
		id: "core.application-signing.status.notSigned",
		defaultMessage: "Not Signed"
	},
	downloadTooltip: {
		id: "core.application-signing.status.downloadTooltip",
		defaultMessage: "Last signed version of the document"
	}
});

const useStyles = makeStyles(
	theme => ({
		cellText: { fontSize: "12px", textWrap: "noWrap" }
	}),
	{ name: "AppSigningStatus" }
);

const AppSigningStatus = (props: AppSigningStatusProps) => {
	const { documents, hideSigningActions, useIndividualSignerStatus } = props;
	const intl = useIntl();
	const classes = useStyles();

	const cellStyle: CSSProperties = {
		fontSize: 12,
		width: "auto",
		whiteSpace: "nowrap"
	};
	const resetSignature = useFetcher(SigningDocumentResource.resetSignature());

	const [errorMessage, setErrorMessage] = useState("");

	const useColumnSort = !(useTenant().tenant?.tenantName === "DNBFI");

	return !useIndividualSignerStatus ? (
		<Suspense fallback={<LinearProgress />}>
			<DployTable
				title={intl.formatMessage(messages.title)}
				columns={[
					{
						title: intl.formatMessage(messages.documentGroup),
						field: "documentName",
						sorting: useColumnSort,
						cellStyle
					},
					{
						title: intl.formatMessage(messages.read),
						field: "read",
						type: "boolean",
						sorting: useColumnSort,
						cellStyle
					},
					{
						title: intl.formatMessage(messages.partlySigned),
						field: "partiallySigned",
						type: "boolean",
						sorting: useColumnSort,
						cellStyle
					},
					{
						title: intl.formatMessage(messages.signed),
						field: "signed",
						type: "boolean",
						sorting: useColumnSort,
						cellStyle
					}
				]}
				data={documents || []}
				actions={
					hideSigningActions
						? undefined
						: [
								({ allowResetSignature, canResetSignature }: SigningDocument) =>
									allowResetSignature && canResetSignature
										? {
												icon: () => <ResetIcon />,
												tooltip: intl.formatMessage(messages.reSign),
												onClick: (e, { esignTaskId }: SigningDocument) => {
													if (
														!window.confirm(
															"Dette vil og resette alle dokumenter hvor signatarer på dette dokumentet er berørt"
														)
													)
														return;
													if (esignTaskId)
														return resetSignature({}, { id: esignTaskId });
												}
										  }
										: (undefined as any),
								({
									download,
									allowDocumentDownload,
									documentName,
									applicationNumber
								}: SigningDocument) =>
									allowDocumentDownload && download
										? {
												icon: () => {
													return <DownloadIcon />;
												},
												tooltip: intl.formatMessage(messages.download),
												onClick: async e => {
													e.stopPropagation();
													setErrorMessage("");

													// const options = {
													// 	method: "get",
													// 	credentials: "same-origin",
													// 	headers: {
													// 		"Content-Type": "application/pdf"
													// 	}
													// };

													try {
														const response = await fetch(download);
														if (!response.ok) {
															throw new Error("Server error");
														}
														const blob = await response.blob();

														const fileName = `${documentName}_${applicationNumber}`;

														downloadUtil(blob, fileName);
													} catch (error: any) {
														console.error(error);
														setErrorMessage(intl.formatMessage(messages.error));
													}
												}
										  }
										: (undefined as any)
						  ]
				}
				options={{
					actionsColumnIndex: -1,
					search: false,
					draggable: false,
					sorting: false,
					headerStyle: cellStyle,
					paging: documents != null && documents.length > 5,
					showEmptyDataSourceMessage: false,
					emptyRowsWhenPaging: false,
					header: true,
					idSynonym: "esignTaskId"
				}}
			/>
			{errorMessage && (
				<Typography color="error" variant="subtitle2">
					{errorMessage}
				</Typography>
			)}
		</Suspense>
	) : (
		// New design that shows status for each induvidual signer
		<Suspense fallback={<LinearProgress />}>
			<DployTable
				title={intl.formatMessage(messages.title)}
				columns={[
					{
						title: intl.formatMessage(messages.documentGroup),
						field: "documentName",
						sorting: useColumnSort,
						cellStyle
					},
					{
						title: intl.formatMessage(messages.personsToSign),
						field: "personsToSign",
						type: "string",
						sorting: useColumnSort,
						cellStyle,
						render: documents => (
							<Grid container spacing={1}>
								{documents.signersToSign.map((x: ApplicationSigner) => {
									if (
										!x.mustSign ||
										!(
											documents.signerTypes.some(y => y === x.signerType) ||
											documents.signerTypesToRead.some(y => y === x.signerType)
										)
									)
										return null;

									return (
										<Grid item xs={12}>
											{x.firstName + " " + x.lastName}
										</Grid>
									);
								})}
							</Grid>
						)
					},
					{
						title: intl.formatMessage(messages.status),
						field: "status",
						type: "string",
						sorting: useColumnSort,
						cellStyle,
						render: documents => (
							<Grid container direction="column" spacing={1} style={{}}>
								{documents.signersToSign.map((x: ApplicationSigner) => {
									if (
										!x.mustSign ||
										!(
											documents.signerTypes.some(y => y === x.signerType) ||
											documents.signerTypesToRead.some(y => y === x.signerType)
										)
									)
										return null;

									if (
										documents.expectedStatusId === 2 &&
										documents.signers.includes(x.applicationSignerId as ID)
									) {
										return (
											<Grid item>
												<Typography
													className={classes.cellText}
													style={{ color: "green" }}
												>
													{intl.formatMessage(messages.signed) + " - "}
													<FormattedDate
														value={
															documents.signTimes?.find(
																y => x.applicationSignerId === y.key
															)?.value
														}
														format="2-digit"
													/>
												</Typography>
											</Grid>
										);
									} else if (
										documents.expectedStatusId === 3 &&
										documents.signers.includes(x.applicationSignerId as ID)
									) {
										return (
											<Grid item>
												<Typography
													className={classes.cellText}
													style={{ color: "green" }}
												>
													{intl.formatMessage(messages.read) + " - "}
													<FormattedDate
														value={
															documents.signTimes?.find(
																y => x.applicationSignerId === y.key
															)?.value
														}
														format="2-digit"
													/>
												</Typography>
											</Grid>
										);
									} else {
										return (
											<Grid item>
												<Typography
													className={classes.cellText}
													style={{ color: "orange" }}
												>
													{documents.expectedStatusId === 3
														? intl.formatMessage(messages.notRead)
														: intl.formatMessage(messages.notSigned)}
												</Typography>
											</Grid>
										);
									}
								})}
							</Grid>
						)
					},
					{
						title: intl.formatMessage(messages.download),
						field: "download",
						type: "string",
						sorting: useColumnSort,
						cellStyle,
						render: documents => (
							<Tooltip title={intl.formatMessage(messages.downloadTooltip)}>
								<Grid
									container
									spacing={1}
									onClick={async () => {
										setErrorMessage("");

										try {
											const response = await fetch(
												documents.download as string
											);
											if (!response.ok) {
												throw new Error("Server error");
											}
											const blob = await response.blob();

											const fileName = `${documents.documentCode}_${documents.applicationNumber}`;

											downloadUtil(blob, fileName);
										} catch (error: any) {
											console.error(error);
											setErrorMessage(intl.formatMessage(messages.error));
										}
									}}
									style={{ cursor: "pointer" }}
								>
									{documents.download != null ? (
										<>
											<Grid item>
												<Typography
													className={classes.cellText}
													style={{ textDecoration: "underline" }}
												>
													{intl.formatMessage(messages.download)}
												</Typography>
											</Grid>
											<Grid item>
												<i className="fa fa-download" />
											</Grid>
										</>
									) : null}
								</Grid>
							</Tooltip>
						)
					}
				]}
				data={documents || []}
				options={{
					actionsColumnIndex: -1,
					search: false,
					draggable: false,
					sorting: false,
					headerStyle: cellStyle,
					paging: documents != null && documents.length > 5,
					showEmptyDataSourceMessage: false,
					emptyRowsWhenPaging: false,
					header: true,
					idSynonym: "esignTaskId",
					overflowY: "visible"
				}}
			/>
			<Grid
				container
				spacing={1}
				style={{ justifyContent: errorMessage ? "space-between" : "right" }}
			>
				<Grid item>
					{errorMessage && (
						<Typography color="error" variant="subtitle2">
							{errorMessage}
						</Typography>
					)}
				</Grid>
				{documents!.filter(x => x.download !== undefined && x.download !== null)
					.length > 0 ? (
					<Grid item>
						<Tooltip title={intl.formatMessage(messages.downloadTooltip)}>
							<Grid
								container
								spacing={1}
								style={{ cursor: "pointer" }}
								onClick={() => {
									setErrorMessage("");

									documents
										?.filter(x => x.download != null)
										.forEach(async doc => {
											try {
												const response = await fetch(doc.download as string);
												if (!response.ok) {
													throw new Error("Server error");
												}
												const blob = await response.blob();

												const fileName = `${doc.documentCode}_${doc.applicationNumber}`;

												downloadUtil(blob, fileName);
											} catch (error: any) {
												console.error(error);
												setErrorMessage(intl.formatMessage(messages.error));
											}
										});
								}}
							>
								<Grid item>
									<Typography
										className={classes.cellText}
										style={{ textDecoration: "underline", fontSize: "14px" }}
									>
										{intl.formatMessage(messages.downloadAllDocuments)}
									</Typography>
								</Grid>
								<Grid item>
									<i className="fa fa-download" />
								</Grid>
							</Grid>
						</Tooltip>
					</Grid>
				) : (
					<div />
				)}
			</Grid>
		</Suspense>
	);
};

AppSigningStatus.displayName = "DployAppSigningStatus";

export { AppSigningStatus };
