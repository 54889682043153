import React, { memo, useCallback, useState, useMemo } from "react";

import marked from "@ploy-lib/purify-marked";
import { getIn } from "formik";
import clsx from "clsx";

import { defineMessages, FormattedMessage } from "react-intl";

import { Modal, Paper, makeStyles, Theme } from "@material-ui/core";
import { Icon, Box, Button, FormHelperText } from "@material-ui/core";

import Send from "@material-ui/icons/Send";
import NavigateBefore from "@material-ui/icons/NavigateBefore";
import NavigateNext from "@material-ui/icons/NavigateNext";
import MoreVert from "@material-ui/icons/MoreVert";

import { download, legacyApiResourceUrl } from "@ploy-lib/core";
import { PendingButton, PendingButtonProps } from "@ploy-ui/core";
import { LoadingOverlay } from "./LoadingOverlay";

import {
	identityRecordOfFieldEditorOptions,
	DployFormControl,
	getFieldError,
	FieldRoleData
} from "@ploy-ui/form-fields";

import { usePageState } from "../../PageContext";
import { useNavigationState } from "../../NavigationContext";

import {
	useTemplateFieldIsVisible,
	useTemplateSectionIsVisible,
	useFormHasErrors
} from "../../hooks";

import { AppActions } from "../../components/AppActions";
import { touchAllVisibleFields } from "../../components/TouchVisibleFieldsOnPreviousPages";
import { usePages } from "../../pagesContext";
import {
	isButtonColor,
	isButtonVariant,
	ButtonFieldProps,
	commonButtonColors,
	commonButtonVariants
} from "./ButtonCommon";

import { LiteralBaseInternal } from "../literals/TextLiteral";
import { ServiceResult } from "@ploy-lib/calculation";
import icons from "./Icons";
import { Extends } from "../../types";
import { useGTMExtended } from "../../thirdPartyScripts/GoogleTagManagerHandler";

export type ActionRole =
	| "navigate_before"
	| "navigate_next"
	| "navigate_home"
	| "submit"
	| "submit_no_validate"
	| "appActions"
	| "validateForm";

type SelectableActionRoles = Extends<
	ActionRole,
	| "navigate_before"
	| "navigate_next"
	| "navigate_home"
	| "submit_no_validate"
	| "submit"
	| "validateForm"
>;

const buttonRoleLocalization = defineMessages<SelectableActionRoles>({
	navigate_before: {
		id: "buttonRoleScope.navigate_before",
		defaultMessage: "Navigate to previous"
	},
	navigate_next: {
		id: "buttonRoleScope.navigate_next",
		defaultMessage: "Navigate to next"
	},
	submit: {
		id: "buttonRoleScope.submit",
		defaultMessage: "Submit"
	},
	submit_no_validate: {
		id: "buttonRoleScope.submit_no_validate",
		defaultMessage: "Submit, no validation"
	},
	navigate_home: {
		id: "buttonRoleScope.navigate_home",
		defaultMessage: "Navigate to home"
	},
	validateForm: {
		id: "buttonRoleScope.validateForm",
		defaultMessage: "Validate form"
	}
});

const useStyles = makeStyles((theme: Theme) => ({
	modal: {
		position: "absolute",
		width: "100%",
		backgroundColor: "rgba(0,0,0,0.5)",
		padding: theme.spacing(3),
		display: "flex",
		overflowY: "auto"
	},
	modalContainer: {
		margin: "auto"
	},
	modalContent: { padding: 30, width: 350 },
	modalConfirmButton: {
		marginTop: 10,
		width: "100%",
		backgroundColor: "lightgray"
	},
	buttonAsText: {
		margin: 0
	},
	mailtoButton: ({ textAlign }: ButtonFieldProps) => ({
		textAlign: textAlign
	})
}));
function ButtonFieldImpl({
	className,
	label,
	color,
	variant,
	role,
	icon,
	form,
	field,
	onClick,
	fullWidth,
	disabled,
	pending,
	success,
	margin,
	startIcon,
	saveOnClick,
	endIcon,
	showOverlay,
	errorDisplay,
	options = {},
	buttonSize,
	muiStartIcon,
	muiEndIcon
}: ButtonFieldProps) {
	const {
		touched,
		setTouched,
		errors,
		setErrors,
		setFieldValue,
		submitForm,
		setSubmitting
	} = form;
	const pushGTMEvent = useGTMExtended();
	const [hasBeenClicked, setHasBeenClicked] = useState(false);
	const buttonColor = isButtonColor(color) ? color : undefined;
	const buttonVariant = isButtonVariant(variant) ? variant : undefined;
	const { style } = options;

	const pages = usePages();
	const { next, prev, step, isLastStep, getStepHref } = usePageState();
	const formHasErrors = useFormHasErrors();

	const hasFormErrors = useMemo(
		() => formHasErrors(form, undefined, true),
		[formHasErrors, form]
	);

	const errorProps = useMemo(
		() => getFieldError(errors, touched, field.name, errorDisplay),
		[errorDisplay, errors, field.name, touched]
	);

	const hasPageErrors = useMemo(
		() => formHasErrors(form, pages.slice(0, step + 1), false),
		[formHasErrors, form, pages, step]
	);

	const [appActionsAnchor, setAppActionsAnchor] = useState<
		Element | ((element: Element) => Element) | null | undefined
	>();

	const fieldIsVisible = useTemplateFieldIsVisible();
	const sectionIsVisible = useTemplateSectionIsVisible();

	const navigateNext = useCallback(() => {
		const relevantTouched = touchAllVisibleFields(
			touched,
			pages.slice(0, step + 1),
			fieldIsVisible,
			sectionIsVisible
		);

		setTouched(relevantTouched);
		if (
			!formHasErrors(
				{ ...form, touched: relevantTouched },
				pages.slice(0, step + 1),
				false
			)
		) {
			if (saveOnClick) fetch(legacyApiResourceUrl("AppLoanLeasing/Save"));
			next();
		}
	}, [
		fieldIsVisible,
		form,
		formHasErrors,
		next,
		pages,
		saveOnClick,
		sectionIsVisible,
		setTouched,
		step,
		touched
	]);

	const validateForm = useCallback(
		async e => {
			const allTouched = touchAllVisibleFields(
				touched,
				pages,
				fieldIsVisible,
				sectionIsVisible
			);

			setTouched(allTouched);

			if (formHasErrors({ errors, touched: allTouched }, undefined, true)) {
				throw new Error("Failed to validate");
			}

			if (onClick) return onClick(e) as any;
		},
		[
			errors,
			fieldIsVisible,
			formHasErrors,
			onClick,
			pages,
			sectionIsVisible,
			setTouched,
			touched
		]
	);

	const submit = useCallback(
		async (shouldValidate: Boolean, e) => {
			setSubmitting(true);
			setHasBeenClicked(true);

			const allTouched = touchAllVisibleFields(
				touched,
				pages,
				fieldIsVisible,
				sectionIsVisible,
				true
			);

			if (shouldValidate) {
				setTouched(allTouched);

				if (formHasErrors({ errors, touched: allTouched }, undefined, true)) {
					setSubmitting(false);
					throw new Error("Failed to validate");
				}
			}
			const result = onClick
				? ((await onClick(e)) as any as ServiceResult | null)
				: null;

			if (onClick && result !== null) {
				pushGTMEvent("purchase", result.data);
				// Stop futher submit stuff if redirecting
				if (result.data && result.data.RedirectURL) return;

				if (result.ok && result.data) {
					setFieldValue("__calculation.submitResult", result.data);
					submitForm();
				} else {
					setSubmitting(false);
					throw new Error("Failed to validate");
				}
			} else {
				submitForm();
			}

			if (!shouldValidate) {
				setTouched(touched);
				setErrors(errors);
			}
		},
		[
			errors,
			fieldIsVisible,
			formHasErrors,
			onClick,
			pages,
			pushGTMEvent,
			sectionIsVisible,
			setErrors,
			setFieldValue,
			setSubmitting,
			setTouched,
			submitForm,
			touched
		]
	);

	const navigation = useNavigationState();

	const roleHrefMap: Partial<Record<ActionRole, string>> = {
		navigate_before: getStepHref(step - 1),
		navigate_next: getStepHref(step + 1)
	};

	const roleClickMap: Record<ActionRole, typeof onClick> = {
		navigate_before: e => {
			e.preventDefault();
			prev();
		},
		navigate_next: e => {
			e.preventDefault();
			navigateNext();
		},
		navigate_home: () => navigation.home(),
		submit: useCallback(async e => submit(true, e), [submit]),
		submit_no_validate: useCallback(async e => submit(false, e), [submit]),
		appActions: ({ currentTarget }) => setAppActionsAnchor(currentTarget),
		validateForm: validateForm
	};

	const labelIcon =
		icon && typeof icon === "string" ? <Icon className={icon} /> : icon;

	const roleIconMap: Record<
		ActionRole,
		Pick<PendingButtonProps, "endIcon" | "startIcon">
	> = {
		navigate_before: {
			startIcon: labelIcon ?? <NavigateBefore />
		},
		navigate_next: {
			endIcon: labelIcon ?? <NavigateNext />
		},
		navigate_home: {},
		submit: {
			endIcon: labelIcon ?? <Send />
		},
		submit_no_validate: {
			endIcon: labelIcon ?? <Send />
		},
		appActions: {
			endIcon: labelIcon ?? <MoreVert />
		},
		validateForm: { endIcon: labelIcon ?? <Send /> }
	};

	const roleDisabledMap: Record<ActionRole, boolean | undefined> = {
		navigate_before: step <= 0,
		navigate_next: isLastStep || hasPageErrors,
		navigate_home: !isLastStep,
		submit: disabled || (hasBeenClicked && hasFormErrors),
		submit_no_validate: disabled,
		appActions: !isLastStep,
		validateForm: hasFormErrors
	};

	const iconProps: Pick<PendingButtonProps, "endIcon" | "startIcon"> =
		startIcon || endIcon
			? {
					startIcon,
					endIcon
			  }
			: (role && roleIconMap[role]) ?? {
					endIcon: labelIcon
			  };

	const pendingSpinnerProps = usePendingSpinnerProps(
		pending,
		role,
		showOverlay
	);

	return (
		<DployFormControl
			margin={margin as any}
			fullWidth={fullWidth}
			className={className}
		>
			{pendingSpinnerProps.suspenseOverlay}

			<PendingButton
				style={style}
				component={role && roleHrefMap[role] ? "a" : undefined}
				href={role && roleHrefMap[role]}
				onClick={(role && roleClickMap[role]) || onClick}
				pending={pending}
				success={role === "submit" ? false : success}
				error={role === "submit" ? false : errorProps?.error}
				helperText={errorProps?.helperText}
				fullWidth={fullWidth}
				color={buttonColor}
				variant={buttonVariant}
				disabled={(role && roleDisabledMap[role]) || disabled}
				size={buttonSize || "large"}
				hideSpinner={pendingSpinnerProps.hideSpinnerIconFromButton}
				startIcon={(muiStartIcon && icons[muiStartIcon]) || iconProps.startIcon}
				endIcon={(muiEndIcon && icons[muiEndIcon]) || iconProps.endIcon}
			>
				{label}
			</PendingButton>
			{role === "appActions" && isLastStep && (
				<AppActions
					form={form}
					anchor={appActionsAnchor}
					setAnchor={setAppActionsAnchor}
				/>
			)}
		</DployFormControl>
	);
}

ButtonFieldImpl.displayName = "ButtonField";
export const ButtonField = memo(ButtonFieldImpl);

function ButtonLinkImpl(props: ButtonFieldProps) {
	const {
		label,
		color,
		variant,
		icon,
		field,
		form,
		onClick,
		fullWidth,
		disabled,
		options: { href, target, style },
		errorDisplay,
		margin,
		modalText,
		startIcon,
		endIcon,
		mailto,
		pageToOpen,
		linkIsUrl,
		buttonSize,
		muiStartIcon,
		muiEndIcon
	} = props;
	const { value, name } = field;
	const { errors, touched } = form;

	const errorProps = getFieldError(errors, touched, field.name, errorDisplay);
	const error = getIn(form.errors, name);

	const { goto, labels } = usePageState();

	const buttonColor = isButtonColor(color) ? color : undefined;
	const buttonVariant = isButtonVariant(variant) ? variant : undefined;

	const [success, setSuccess] = useState(false);
	const [responseError, setResponseError] = useState<string>();
	const [modalOpen, setModalOpen] = useState(false);

	const classes = useStyles(props);

	const labelIcon =
		icon && typeof icon === "string" ? <Icon className={icon} /> : icon;

	const onClickHandler: typeof onClick = async e => {
		form.setFieldTouched(name, true);
		setResponseError(undefined);
		setSuccess(false);

		if (!onClick || error) return;

		try {
			const response: any = await onClick(e);

			if (response && response.data instanceof Blob) {
				download(response.data);
			}
			if (response && response.data && response.data.Error)
				setResponseError(response.data.Error);
			else setSuccess(true);
		} catch (e: any) {
			console.error(e);
		}
	};

	const button = mailto ? (
		value ? (
			<LiteralBaseInternal {...props}>
				<a className={classes.mailtoButton} href={"mailto: " + value}>
					{value}
				</a>
			</LiteralBaseInternal>
		) : (
			<></>
		)
	) : href || pageToOpen ? (
		<Button
			style={style}
			color={buttonColor}
			variant={buttonVariant}
			fullWidth={fullWidth}
			disabled={disabled || errorProps?.error}
			size={buttonSize || "large"}
			component="a"
			onClick={e => {
				if (!linkIsUrl) {
					if (pageToOpen) {
						const index = (labels as string[]).indexOf(pageToOpen);
						if (index !== -1) goto(index);
						else goto(Number(pageToOpen));
					} else {
						form.setFieldTouched(name, true);
						if (error) e.preventDefault();
					}
				} else {
					window.open(pageToOpen, "_blank");
				}
			}}
			href={href}
			target={target}
			startIcon={muiStartIcon && icons[muiStartIcon]}
			endIcon={labelIcon || (muiEndIcon && icons[muiEndIcon])}
		>
			{label}
		</Button>
	) : (
		<PendingButton
			style={style}
			color={buttonColor}
			variant={buttonVariant}
			fullWidth={fullWidth}
			disabled={disabled || errorProps?.error}
			size={buttonSize || "large"}
			onClick={async e => {
				if (modalText) setModalOpen(true);
				else await onClickHandler(e);
			}}
			success={false}
			startIcon={startIcon || (muiStartIcon && icons[muiStartIcon])}
			endIcon={(endIcon ?? labelIcon) || (muiEndIcon && icons[muiEndIcon])}
		>
			{label}
		</PendingButton>
	);

	const helperText =
		(success && value) ||
		(errorProps?.error && errorProps.helperText) ||
		responseError;

	return (
		<DployFormControl
			className={clsx({ [classes.buttonAsText]: buttonVariant === "text" })}
			error={errorProps?.error || Boolean(responseError)}
			margin={mailto ? "none" : (margin as any)}
			fullWidth={fullWidth}
		>
			<Modal
				className={classes.modal}
				open={modalOpen}
				onClose={() => setModalOpen(false)}
			>
				<Paper className={classes.modalContainer}>
					<div className={classes.modalContent}>
						<div
							dangerouslySetInnerHTML={{
								__html: marked(modalText || "")
							}}
						/>
						<Button
							className={classes.modalConfirmButton}
							onClick={e => {
								setModalOpen(false);
								onClickHandler(e);
							}}
						>
							<FormattedMessage
								id="ploy-ui.template-form.buttons.buttonlink.confirm"
								defaultMessage="Aksepter"
								description="Confirm button for download modal"
							/>
						</Button>
					</div>
				</Paper>
			</Modal>
			{button}
			{helperText && (
				<Box
					component={FormHelperText}
					textAlign="center"
					color={
						errorProps?.error || Boolean(responseError)
							? "error.main"
							: success
							? "success.main"
							: undefined
					}
				>
					{helperText}
				</Box>
			)}
		</DployFormControl>
	);
}

ButtonLinkImpl.displayName = "ButtonLink";
export const ButtonLink = memo(ButtonLinkImpl);

const preparedButtonRoles: FieldRoleData[] = Object.entries(
	buttonRoleLocalization
).map(([k, v]) => ({
	name: k,
	localization: v
}));

export const EditorButtonFields = identityRecordOfFieldEditorOptions({
	ButtonField: {
		fieldRoles: preparedButtonRoles,
		editableOptions: {
			variant: commonButtonVariants,
			color: commonButtonColors,
			buttonSize: ["small", "medium", "large"],
			muiStartIcons: Object.keys(icons),
			muiEndIcons: Object.keys(icons)
		}
	},
	ButtonLink: {
		fieldRoles: preparedButtonRoles,
		editableOptions: {
			variant: commonButtonVariants,
			color: commonButtonColors,
			linkIsUrl: true,
			mailto: true,
			modalText: true,
			pageToOpen: true,
			buttonSize: ["small", "medium", "large"],
			muiStartIcons: Object.keys(icons),
			muiEndIcons: Object.keys(icons)
		}
	}
});

const usePendingSpinnerProps = (
	pending?: boolean,
	role?: string,
	showOverlay?: boolean
) => {
	const displayPendingSuspenseSpinnerOverlay =
		(role === "submit" || showOverlay) && pending;
	return {
		hideSpinnerIconFromButton: displayPendingSuspenseSpinnerOverlay,
		suspenseOverlay: displayPendingSuspenseSpinnerOverlay && (
			<LoadingOverlay pending={pending} />
		)
	};
};
