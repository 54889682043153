import { ID, BrowserDeprecation } from "@ploy-lib/types";
import { legacyApiResourceUrl } from "@ploy-lib/core";
import { SimpleRecord, AbstractInstanceType } from "@rest-hooks/rest";
import { BasePlainResource, BaseCamelCasedResource } from "./BasePlainResource";
import paramsToString from "../resources/BaseResource";

export interface LoginUser {
	name: string;
	username: string;
}

export interface LoginVendor {
	id: ID;
	name: string;
}

export type LoginStatus =
	| "NotSet"
	| "ChangePassword"
	| "SupplyMobile"
	| "ChooseVendor"
	| "AccountStatusOK"
	| "TwoFactorAuthValidation";

export enum LoginStatusEnum {
	NotSet = "NotSet",
	ChangePassword = "ChangePassword",
	SupplyMobile = "SupplyMobile",
	ChooseVendor = "ChooseVendor",
	AccountStatusOK = "AccountStatusOK",
	TwoFactorAuthValidation = "TwoFactorAuthValidation"
}

export class LoginResource extends BasePlainResource {
	readonly status: LoginStatusEnum = LoginStatusEnum.NotSet;
	readonly styleId?: number;
	readonly user?: LoginUser;
	readonly vendor?: LoginVendor;
	readonly vendors?: LoginVendor[];
	readonly isInternal: boolean = false;
	readonly isDirectUser: boolean = false;
	readonly browserDeprecation: BrowserDeprecation = {};
	readonly googleTagManagerCode?: string;
	readonly cookiebotId?: string;
	readonly error?: string;
	readonly redirectLink?: {
		href: string;
		label: string;
	};
	readonly locale?: string;

	get isAuthenticated(): boolean {
		return this.status === LoginStatusEnum.AccountStatusOK;
	}

	pk() {
		return "Login";
	}

	static merge<T extends typeof SimpleRecord>(
		this: T,
		first: AbstractInstanceType<T>,
		second: AbstractInstanceType<T>
	): AbstractInstanceType<T> {
		// Always overwrite
		return second;
	}

	static status(this: typeof LoginResource) {
		return this.detail().extend({
			url: urlParams => `${this.urlRoot}/Login`
		});
	}

	static login(this: typeof LoginResource) {
		return this.create().extend({
			url: urlParams => `${this.urlRoot}/Login`
		});
	}

	static tokenLogin(this: typeof LoginResource) {
		const tokenEndpoint = this.create().extend({
			url: urlParams => `${this.urlRoot}/tokenLogin`
		});

		const statusEndpoint = this.status();

		// Cache as if it's a LoginResource.status() request
		// useResource(LoginResource.status(), {}) will then use this cached response instead
		return tokenEndpoint.extend({
			sideEffect: undefined,
			key: () => statusEndpoint.key({}),
			fetch: params => tokenEndpoint.fetch({}, params)
		});
	}

	static setVendor(this: typeof LoginResource) {
		return this.login();
	}

	static setMobile(this: typeof LoginResource) {
		return this.login();
	}

	static setPassword(this: typeof LoginResource) {
		return this.login();
	}

	static logout(this: typeof LoginResource) {
		return this.create().extend({
			url: () => `${this.urlRoot}/Logout`
		});
	}

	static selectVendor(this: typeof LoginResource) {
		return this.create().extend({
			url: () => `${this.urlRoot}/SelectVendor`
		});
	}

	static urlRoot = legacyApiResourceUrl("Login");
}

export class TokenLoginResource extends BasePlainResource {
	readonly status: LoginStatusEnum = LoginStatusEnum.NotSet;
	readonly style?: string;
	readonly user?: LoginUser;
	readonly vendor?: LoginVendor;
	readonly isInternal = false;
	readonly isDirectUser = false;
	readonly browserDeprecation: BrowserDeprecation = {};
	readonly googleTagManagerCode?: string;
	readonly cookiebotId?: string;

	get isAuthenticated(): boolean {
		return this.status === LoginStatusEnum.AccountStatusOK;
	}

	pk() {
		return "TokenLogin";
	}

	static merge<T extends typeof SimpleRecord>(
		this: T,
		first: AbstractInstanceType<T>,
		second: AbstractInstanceType<T>
	): AbstractInstanceType<T> {
		// Always overwrite
		return second;
	}

	static tokenLogin(this: typeof TokenLoginResource) {
		const endpoint = this.create().extend({
			url: () => `${this.urlRoot}/tokenLogin`
		});

		return endpoint.extend({
			fetch: params => endpoint.fetch({}, params)
		});
	}

	static urlRoot = legacyApiResourceUrl("Login");
}

export class PasswordResetResource extends BaseCamelCasedResource {
	readonly success?: boolean;
	readonly message?: string;
	readonly error?: string;
	readonly direct?: boolean;

	static requestPasswordChange(this: typeof PasswordResetResource) {
		return this.create().extend({
			url: () => `${this.urlRoot}/RequestPasswordChange`
		});
	}

	static updatePassword(this: typeof PasswordResetResource) {
		return this.create().extend({
			url: () => `${this.urlRoot}/ChangePassword`
		});
	}

	static resetPassword(this: typeof PasswordResetResource) {
		return this.create().extend({
			url: () => `${this.urlRoot}/ResetPwd`
		});
	}

	pk() {
		return "ResetPwd";
	}

	static urlRoot = legacyApiResourceUrl("Login");
}

export class LoginInfoResource extends BaseCamelCasedResource {
	readonly header?: boolean;
	readonly message?: string;

	pk() {
		return "GetLoginInfoMessage";
	}

	static urlRoot = legacyApiResourceUrl("Login");
}
