import React, { useEffect, useState } from "react";

import { defineMessages, useIntl } from "react-intl";
import { useTemplateDebugManager } from "@ploy-lib/calculation";

import { PendingButtonProps } from "@ploy-ui/core";
import {
	FieldRoleData,
	identityRecordOfFieldEditorOptions
} from "@ploy-ui/form-fields";

import { useStatefulResource } from "@rest-hooks/legacy";
import { legacyApiResourceUrl } from "@ploy-lib/core";

import {
	ButtonFieldProps,
	commonButtonColors,
	commonButtonVariants
} from "./ButtonCommon";

import { ButtonField } from "./Button";
import {
	FunctionButtonResource,
	StyledIcon
} from "../../components/AppActions";
import {
	SelectableAppActionRoles,
	appActionButtonRoleLocalization,
	useAppActionClick
} from "../../hooks/useAppActionClick";

import { usePageState } from "../..";
import { useAppLoad } from "../../appLoadContext";

import { ReasonForCancelModal } from "../../components/ReasonForCancelModal";

const appActionMessages = defineMessages({
	appAction_cancel: {
		id: "template-form.app-action-button.roles.appAction_cancel",
		defaultMessage: "Cancel"
	},
	appAction_copy: {
		id: "template-form.app-action-button.roles.appAction_copy",
		defaultMessage: "Copy"
	},
	appAction_makeOffer: {
		id: "template-form.app-action-button.roles.appAction_makeOffer",
		defaultMessage: "Make offer"
	},
	appAction_replace: {
		id: "template-form.app-action-button.roles.appAction_replace",
		defaultMessage: "Replace"
	},
	appAction_save: {
		id: "template-form.app-action-button.roles.appAction_save",
		defaultMessage: "Save"
	},
	appAction_notifyFlowAction_CustomerAcceptedOffer: {
		id: "template-form.app-action-button.roles.appAction_acceptOffer",
		defaultMessage: "Accept offer"
	},
	appAction_notifyFlowAction_UpdateAgreementConditions: {
		id: "template-form.app-action-button.roles.appAction_notifyFlowAction",
		defaultMessage: "Update agreement conditions"
	},
	appAction_cancel_confirmDialog: {
		id: "template-form.app-action-button.roles.appAction_cancel_confirmDialog",
		defaultMessage: "Er du sikker på at du vil bortfalle saken?"
	},
	appAction_cancelWithReason: {
		id: "template-form.app-action-button.roles.appAction_cancelWithReason",
		defaultMessage: "Cancel with reason"
	},
	appAction_notifyFlowAction_CompletedFormStepCustomer: {
		id: "template-form.app-action-button.roles.appAction_completedFormStepCustomer",
		defaultMessage: "Completed customer step"
	},
	appAction_notifyFlowAction_CompletedFormStepConsent: {
		id: "template-form.app-action-button.roles.appAction_completedFormStepConsent",
		defaultMessage: "Completed consent step"
	},
	appAction_notifyFlowAction_CompletedFormStepFinance: {
		id: "template-form.app-action-button.roles.appAction_completedFormStepFinance",
		defaultMessage: "Completed finance step"
	},
	appAction_notifyFlowAction_CompletedFormStepObject: {
		id: "template-form.app-action-button.roles.appAction_completedFormStepObject",
		defaultMessage: "Completed object step"
	},
	appAction_notifyFlowAction_CompletedFormStepSecurity: {
		id: "template-form.app-action-button.roles.appAction_completedFormStepSecurity",
		defaultMessage: "Completed security step"
	}
});

const preparedAppActionButtonRoles: FieldRoleData[] = Object.entries(
	appActionButtonRoleLocalization
).map(([k, v]) => ({
	name: k,
	localization: v
}));

export const AppActionButton = ({
	label,
	disabled: _1,
	action,
	pending: _2,
	icon: _3,
	onClick,
	options: { disableValidation, alwaysEnabled } = {},
	role,
	...props
}: ButtonFieldProps) => {
	const appLoad = useAppLoad();

	const [, name, flowAction] = action?.split("_") ?? [];
	const intl = useIntl();

	const { data: appActions } = useStatefulResource(
		FunctionButtonResource.list(),
		name && appLoad.id
			? flowAction
				? { includedFunctonButtons: `${name}_${flowAction}` }
				: { includedFunctonButtons: name }
			: null
	);

	const appAction = appActions?.[0];

	const { enabled: debugEnabled } = useTemplateDebugManager();

	const { pending, disabled, success, onClickButton } = useAppActionClick({
		button: appAction,
		actionEvent: flowAction,
		form: props.form,
		disableValidation: disableValidation,
		role: role as SelectableAppActionRoles
	});

	const { isLastStep } = usePageState();
	const [openModal, setOpenModal] = useState(false);
	const [cancelReasons, setCancelReasons] = useState<string[]>([]);
	const [fetched, setFetched] = useState(false);

	useEffect(() => {
		if (appAction?.name === "CancelWithReason" && !fetched) {
			setFetched(true);
			fetch(
				legacyApiResourceUrl(
					"StandardTextList/GetStandardTextsFromDt?code=ReasonForCancelOpt"
				)
			).then(async res => setCancelReasons(await res.json()));
		}
	}, [appAction, fetched]);

	const shouldBeDisabled = disabled || (!alwaysEnabled && !isLastStep);

	if (!appAction && debugEnabled)
		return (
			<ButtonField
				{...props}
				disabled={shouldBeDisabled}
				onClick={() => {}}
				label={label ?? action}
			/>
		);

	if (!appAction) return null;

	const defaultIcon:
		| Pick<PendingButtonProps, "endIcon" | "startIcon">
		| undefined = appAction.icon
		? {
				endIcon: <StyledIcon className={appAction.icon} />
		  }
		: undefined;

	return (
		<>
			<ButtonField
				{...props}
				role={undefined}
				disabled={shouldBeDisabled}
				pending={pending}
				showOverlay={role !== "navigate_next"}
				onClick={event => {
					if (appAction.name === "CancelWithReason") {
						if (
							window.confirm(
								intl.formatMessage(
									appActionMessages.appAction_cancel_confirmDialog
								)
							)
						) {
							if (cancelReasons.length > 0) {
								setOpenModal(true);
							} else {
								onClickButton(event);
							}
						}
					} else {
						onClickButton(event);
					}
				}}
				label={label ? label : appAction.label ?? appAction.name}
				success={success}
				{...defaultIcon}
			/>
			{openModal && (
				<ReasonForCancelModal
					open={openModal}
					setOpen={setOpenModal}
					confirmCancel={onClickButton}
					reasonsForCancel={cancelReasons}
					appId={appLoad.applicationId}
				/>
			)}
		</>
	);
};

AppActionButton.displayName = "DployAppActionButton";

export const EditorAppActionButtons = identityRecordOfFieldEditorOptions({
	AppActionButton: {
		fieldActions: Object.entries(appActionMessages).map(([k, v]) => ({
			name: k,
			localization: v
		})),
		fieldRoles: preparedAppActionButtonRoles,
		editableOptions: {
			variant: commonButtonVariants,
			color: commonButtonColors,
			disableValidation: true,
			action: true
		}
	}
});
