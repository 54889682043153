import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import merge from "lodash/merge";

export const useStyles = makeStyles(
	(theme: Theme) => ({
		mainGrid: {
			padding: theme.spacing(2),
			marginRight: theme.spacing(2),
			marginLeft: theme.spacing(2),
			marginTop: theme.spacing(20),

			[theme.breakpoints.up("md")]: {
				marginTop: theme.spacing(20),
				padding: theme.spacing(8)
			}
		},
		titleText: {
			fontWeight: 500,
			fontSize: "45px",
			[theme.breakpoints.down("sm")]: {
				margin: "auto 8px"
			}
		}
	}),
	{ name: "DployVerticalFormStyles" }
);

export const mainTheme = (theme: Theme): Theme => {
	const overrides = {
		overrides: {
			DployFormSectionGroup: {
				sectionGroupWrapper: {
					padding: 0,
					paddingTop: 0,
					paddingBottom: 0
				}
			},
			DployFormSection: {
				headerMarginLiteral: {
					[theme.breakpoints.up("sm")]: {
						marginLeft: 4
					},
					[theme.breakpoints.up("md")]: {
						marginLeft: 24
					}
				}
			},
			DployFormFieldContainer: {
				styleHelperText: {
					marginTop: 0,
					"& div > pre > code": {
						whiteSpace: "pre-line"
					}
				}
			},
			DployFormLiteral: {
				marginNormal: {
					[theme.breakpoints.down("sm")]: {
						paddingLeft: 4
					},
					[theme.breakpoints.up("md")]: {
						paddingLeft: 24
					}
				}
			},
			DployCalculationField: {
				divider: {
					margin: "8px"
				}
			}
		}
	};
	return merge({}, theme, overrides) as Theme;
};

export const actionsTheme = (theme: Theme): Theme => ({
	...theme,
	overrides: {
		...theme.overrides,
		DployFormPanel: {
			sectionGroupsContainer: {
				marginTop: theme.spacing(2),
				[theme.breakpoints.up("md")]: {}
			}
		},
		DployFormSectionGroup: {
			sectionGroupWrapper: {
				[theme.breakpoints.up("md")]: {
					marginTop: theme.spacing(3)
				},
				padding: 0,
				paddingTop: 0,
				paddingBottom: 0
			},
			sectionGroup: {
				justifyContent: "center"
			}
		},
		DployCalculationField: {
			field: {
				[theme.breakpoints.down("xs")]: {
					width: "100%"
				}
			}
		},
		DployFormFieldContainer: {
			fieldWrapper: {
				[theme.breakpoints.down("xs")]: {
					flexGrow: 1
				}
			}
		},
		MuiButton: {
			...(theme.overrides && theme.overrides.MuiButton),
			root: {
				...(theme.overrides &&
					theme.overrides.MuiButton &&
					theme.overrides.MuiButton.root),
				whiteSpace: "nowrap" as const,
				width: "100%",
				borderRadius: 0
			}
		}
	} as any
});
