import {
	CustomGuiFieldHandling,
	FormTemplate,
	TemplateField,
	TemplatePage
} from "@ploy-lib/types";
import { useMemo } from "react";
import { defineMessages, useIntl } from "react-intl";
import {
	object as YupObject,
	string as YupString,
	array as YupArray,
	number as YupNumber,
	mixed as YupMixed,
	ObjectSchema
} from "yup";

const validationMessages = defineMessages({
	required: {
		id: "calculation-form.validation.required",
		defaultMessage: "Required"
	},
	pleaseConfirm: {
		id: "calculation-form.validation.pleaseConfirm",
		defaultMessage: "Please confirm"
	}
});

const getFieldValueType = (field: TemplateField) => {
	if (field.multiple) return "array";
	if (field.renderAs === "NumberField") return "number";
	if (field.renderAs === "CheckboxWithLabelField") return "confirmation";
	// We shouls support a better way of finding the expected datatype for a given field's value, instead of checking the `renderAs` property
	return "string";
};

type DataForSpecialFieldHandling = {
	fieldsWithIgnoreCgfHandling?: Record<string, Record<string, boolean>>;
	// `initialFieldValues`: initial values for fields. May only be set for TemplateFields that are marked `CgfHandling: "Ignore"`
	initialFieldValues?: Record<string, Record<string, any>>;
	// `validationSchema`: a custom "required" - validation scheme for TemplateFields marked `IsRequired`,
	validationSchema?: ObjectSchema<any>;
};

/**
 *  `useSpecialFieldHandling` is a hook to handle overriding default form/ field behaviour.
 */
export const useSpecialFieldHandling = (
	formTemplate: FormTemplate<TemplatePage> | undefined
) => {
	const intl = useIntl();

	const specialFieldData: DataForSpecialFieldHandling = useMemo(() => {
		if (!formTemplate) return {};

		const allTemplateFieldsInForm: TemplateField[] = formTemplate.pages
			.map(page => page.panels)
			.flatMap(panelDictForPage => Object.values(panelDictForPage)) // page.panels is a dictionary, flatmap out the values for panels
			.flatMap(panel => panel.sections.flatMap(section => section.fields));

		// { namespace_1: requiredFields[], namespace_2: requiredFields[] }
		const requiredFields: Record<
			string,
			{ name: string; fieldValueType: string }[]
		> = allTemplateFieldsInForm
			.filter((field: TemplateField) => field.isRequired)
			.map((field: TemplateField) => ({
				name: field.name,
				namespace: field.namespace,
				fieldValueType: getFieldValueType(field)
			}))
			.reduce((result, reqField) => {
				const namespace = reqField.namespace!;
				// Prevent adding `undefined` to output array when spreading `...[result[namespace]]` inside the `[namespace]` array
				if (namespace in result) {
					return {
						...result,
						[namespace]: [
							...result[namespace],
							{
								name: reqField.name,
								fieldValueType: reqField.fieldValueType
							}
						]
					};
				}
				return {
					...result,
					[namespace]: [
						{
							name: reqField.name,
							fieldValueType: reqField.fieldValueType
						}
					]
				};
			}, {});

		const validationSchema = YupObject().shape(
			Object.keys(requiredFields).reduce((schemaResult, namespaceKey) => {
				return {
					...schemaResult,
					[namespaceKey]: YupObject().shape(
						requiredFields[namespaceKey].reduce((namespaceResult, field) => {
							let validationObject = {};
							if (field.fieldValueType === "array") {
								validationObject[field.name] = YupArray().required(
									intl.formatMessage(validationMessages.required)
								);
							} else if (field.fieldValueType === "number") {
								validationObject[field.name] = YupNumber().typeError(
									intl.formatMessage(validationMessages.required)
								);
							} else if (field.fieldValueType === "confirmation") {
								validationObject[field.name] = YupMixed()
									// .oneOf's message: Errormessage for whenever value changes from a valid value to `false`, `"False"`, `"false"`, etc.
									.oneOf(
										[true, "true", "True", 1],
										intl.formatMessage(validationMessages.pleaseConfirm)
									)
									.required(
										intl.formatMessage(validationMessages.pleaseConfirm)
									);
							} else {
								validationObject[field.name] = YupString().required(
									intl.formatMessage(validationMessages.required)
								);
							}
							return {
								...namespaceResult,
								...validationObject
							};
						}, {})
					)
				};
			}, {})
		);

		const fieldsWithIgnoreCgfHandling = allTemplateFieldsInForm
			.filter(
				(field: TemplateField) =>
					field.cgfHandling === CustomGuiFieldHandling.Ignore
			)
			.reduce(
				(accumulated, field) => ({
					...accumulated,
					[field.namespace!]: {
						...accumulated[field.namespace!],
						[field.name]: true
					}
				}),
				{}
			);

		// { namespace_1: initialFieldValues[], namespace_2: initialFieldValues[] }
		const initialFieldValues: Record<
			string,
			Record<string, any>
		> = allTemplateFieldsInForm
			.filter(
				(field: TemplateField) =>
					field.cgfHandling === CustomGuiFieldHandling.Ignore
			)
			.reduce(
				(accumulated, field) => ({
					...accumulated,
					[field.namespace!]: {
						...accumulated[field.namespace!],
						[field.name]: field.initialFieldValue
					}
				}),
				{}
			);

		var retValue: DataForSpecialFieldHandling = {
			fieldsWithIgnoreCgfHandling,
			initialFieldValues,
			validationSchema
		};
		return retValue;
	}, [formTemplate, intl]);

	return specialFieldData;
};
