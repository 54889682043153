import { CellProps, Hooks } from "react-table";
import "../react-table-config";

import { PendingButton } from "@ploy-ui/core";
import ButtonGroup from "@material-ui/core/ButtonGroup";

import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import React, { useMemo } from "react";
import { useFormikContext } from "formik";
import { RowActions } from "../types";

const pluginName = "useEditableActionsColumn";

export function useEditableActionsColumn<T extends object = {}>(
	hooks: Hooks<T>
) {
	hooks.visibleColumns.push((columns, { instance }) => {
		if (!instance.canAdd && !instance.canEdit && !instance.canDelete)
			return columns;

		return [
			// Let's make a column for selection
			{
				id: "editing",
				disableGroupBy: true,
				disableSortBy: true,
				disableFilters: true,
				disableGlobalFilter: true,
				disableResizing: true,
				disableEditing: true,
				Cell: EditableActionsColumn
			},
			...columns
		];
	});
}

useEditableActionsColumn.pluginName = pluginName;
interface EditableActionsProps<D extends object, V = any>
	extends CellProps<D, V> {
	onRowDelete: RowActions<D>["onRowDelete"];
	onRowAdd: RowActions<D>["onRowAdd"];
}

export function EditableActionsColumn<D extends object, V = any>(
	props: EditableActionsProps<D, V>
) {
	const { validateForm, submitForm, resetForm } = useFormikContext();
	const {
		row,
		canEdit,
		canDelete,
		columns,
		onRowDelete,
		setRowEditing,
		editingRowIds,
		schema,
		state: { selectedRowIds }
	} = props;
	const canEditColumns = useMemo(
		() => !columns.every(c => c.canEdit === false),
		[columns]
	);

	if (row.canExpand) return null;

	if (!props.canAdd && !props.canEdit && !props.canDelete) return null;

	const isSelecting = Object.keys(selectedRowIds).length > 0;
	const isEditing = editingRowIds[row.id];
	const isReadOnlyRow =
		schema?.isReadOnlyRow?.(row.original, row.cells) ?? false;
	const isDeletableRow =
		schema?.isDeletableRow?.(row.original, row.cells) ?? true;
	const canEditRow = canEdit && canEditColumns && !isReadOnlyRow;
	const canDeleteRow = canDelete && !isReadOnlyRow && isDeletableRow;

	return (
		<ButtonGroup size="small" variant="text">
			{!isEditing && canEditRow && (
				<PendingButton
					disabled={isSelecting}
					onClick={() => setRowEditing(row.index, true)}
				>
					<EditIcon fontSize="small" />
				</PendingButton>
			)}

			{isEditing && (
				<PendingButton
					disabled={isSelecting}
					success={false}
					error={false}
					onClick={async () => {
						await validateForm().then(async errors => {
							if (Object.keys(errors).length === 0) {
								await submitForm();
								setRowEditing(row.index, false);
							} else return Promise.reject("Validation failed");
						});
					}}
				>
					<SaveIcon fontSize="small" />
				</PendingButton>
			)}

			{isEditing && (
				<PendingButton
					disabled={isSelecting}
					onClick={() => {
						setRowEditing(row.index, false);
						resetForm();
					}}
				>
					<CancelIcon fontSize="small" />
				</PendingButton>
			)}

			{!isEditing && canDeleteRow && (
				<PendingButton
					disabled={isSelecting}
					onClick={() => onRowDelete(row.id, row.index)}
				>
					<DeleteIcon fontSize="small" />
				</PendingButton>
			)}
		</ButtonGroup>
	);
}
