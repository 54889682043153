import { BaseResource } from "./BaseResource";
import { legacyApiResourceUrl } from "@ploy-lib/core";

export class FlowActionResource extends BaseResource {
	readonly actions: string[];
	readonly updates: string[];

	pk() {
		return "_";
	}

	static urlRoot = legacyApiResourceUrl("Admin/FlowTest/Flows");
}


