import {
	useStyles,
	appActionsTheme,
	listSummaryHeaderTheme,
	mainHeaderTheme,
	mainTheme,
	footerTheme
} from "./styles";
import { TemplatePageProps, LayoutPage } from "../../../types";
import { addRegisteredLayout, PanelSettings } from "../layoutDescriptions";
import { Grid, Paper, MuiThemeProvider } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import { Panel } from "../../../panel";
import { TemplatePanel } from "@ploy-lib/types";
import { LoginResource } from "@ploy-lib/rest-resources";
import { useResource } from "@rest-hooks/core";
import FormStatus from "../../../components/FormStatus";

export type ListPanels = {
	mainHeader: TemplatePanel;
	main: TemplatePanel;
	mainPost: TemplatePanel;
	summaryHeader: TemplatePanel;
	actions: TemplatePanel;
	appActions: TemplatePanel;
	header: TemplatePanel;
	footer: TemplatePanel;
};
export type ListPanelKeys = keyof ListPanels;
export type ListPage = LayoutPage<ListPanelKeys, ListPanels>;

const mainPanelSettings: PanelSettings = {
	editableOptions: {
		title: false,
		elevation: true,
		square: true,
		minHeight: true,
		maxWidth: true,
		desktopViewForMobile: true
	}
};

const panelSettings: Record<ListPanelKeys, PanelSettings> = {
	header: {},
	summaryHeader: {
		defaultOptions: {
			literal: true
		}
	},
	mainHeader: {},
	main: mainPanelSettings,
	mainPost: {},
	actions: {},
	appActions: {},
	footer: {}
};

addRegisteredLayout({
	name: "List",
	displayName: "List",
	panels: panelSettings,
	hideFunctionButtons: true,
	showFormStatus: true
});

addRegisteredLayout({
	name: "CenteredList",
	displayName: "CenteredList",
	panels: panelSettings,
	hideFunctionButtons: true,
	showFormStatus: true
});

export interface ListProps extends TemplatePageProps<ListPage> {
	centered: boolean;
}

export const List = (props: ListProps) => {
	const { panels, hideFunctionButtons, centered, showFormStatus } = props;

	const classes = useStyles(props);

	const login = useResource(LoginResource.status(), {});

	const {
		header,
		summaryHeader,
		mainHeader,
		main,
		mainPost,
		actions,
		appActions,
		footer
	} = panels;

	return (
		<Grid
			container
			spacing={3}
			justifyContent={centered ? "center" : undefined}
		>
			{header.sections.length > 0 && (
				<Grid item xs={12} md={8}>
					<Panel transparent fullWidth justifyContent="flex-end" {...header} />
				</Grid>
			)}
			{!login.isInternal && !hideFunctionButtons && (
				<Grid item xs={12} md={8}>
					<ThemeProvider theme={appActionsTheme}>
						<Panel
							transparent
							fullWidth
							{...appActions}
							justifyContent="flex-end"
						/>
					</ThemeProvider>
				</Grid>
			)}
			{summaryHeader.sections.length > 0 && (
				<Grid item xs={12} md={8}>
					<Paper elevation={0}>
						<MuiThemeProvider theme={listSummaryHeaderTheme}>
							<Panel
								fullWidth
								className={classes.summaryHeader}
								{...summaryHeader}
							/>
						</MuiThemeProvider>
					</Paper>
				</Grid>
			)}
			{mainHeader.sections.length > 0 && (
				<Grid item xs={12} md={8}>
					<MuiThemeProvider theme={mainHeaderTheme}>
						<Panel {...mainHeader} />
					</MuiThemeProvider>
				</Grid>
			)}
			{main.sections.length > 0 && (
				<Grid item xs={12} md={8}>
					<MuiThemeProvider theme={mainTheme}>
						<Panel {...main} />
					</MuiThemeProvider>
				</Grid>
			)}
			{(showFormStatus || actions || mainPost) && (
				<Grid item xs={12} md={8}>
					{mainPost.sections.length > 0 && <Panel transparent {...mainPost} />}
					{showFormStatus && (
						<FormStatus className={classes.errorSection} elevation={0} />
					)}
					{actions && (
						<Panel transparent justifyContent="center" {...actions} />
					)}
				</Grid>
			)}
			{footer && (
				<Grid item xs={12} md={8}>
					<MuiThemeProvider theme={footerTheme}>
						<Panel
							transparent
							fullWidth
							justifyContent="flex-end"
							{...footer}
						/>
					</MuiThemeProvider>
				</Grid>
			)}
		</Grid>
	);
};

export const CenteredList = (props: ListProps) => (
	<List {...props} centered={true} />
);
