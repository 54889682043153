import { useVariableData } from "@ploy-lib/calculation";
import {
	BeneficialOwner,
	BeneficialOwnerResource,
	CustomerInfoSearchResource
} from "@ploy-lib/rest-resources";
import { createValidationHelpers } from "@ploy-lib/validation-helpers";
import { FieldInputProps, FormikProps } from "formik";
import React, { useEffect, useState } from "react";
import { memo, useCallback } from "react";
import { useIntl } from "react-intl";
import { useFetcher, useResource } from "@rest-hooks/core";
import { UpdateBeneficialOwnerFieldView } from ".";
import { useAppLoad } from "../../../appLoadContext";
import { useTemplateFieldIsVisible } from "../../../hooks";
import { InputFieldProps } from "../../../types";
import { createBeneficialOwnerConfig, totalShareValidation } from "./BeneficialOwnerFieldConfig";
import { useSearchParams } from "react-router-dom";

const mapCustomerResourceToBeneficialOwner = (
	customer: Readonly<CustomerInfoSearchResource>
) => {
	const beneficialOwner = {
		firstname: customer?.cust?.firstname,
		lastname: customer?.cust?.lastname,
		name: customer?.cust?.customerName,
		address: customer?.addr?.address1,
		addressCity: customer?.addr?.addressCity,
		addressZip: customer?.addr?.addressZip
	};
	return beneficialOwner as Partial<BeneficialOwner>;
};

/**
 * Wrapper for container and view
 */
const UpdateBeneficialOwnerField = memo((props: InputFieldProps) => {
	const { form, field } = props;
	return <UpdateBeneficialOwnerFieldContainer form={form} field={field} />;
});

interface UpdateBeneficialOwnerFieldContainerProps {
	field: FieldInputProps<any>;
	form: FormikProps<any>;
}
/**
 * Container for UpdateBemeficialOwnerField. Contains logic for fetching and altering of resource
 */
const UpdateBeneficialOwnerFieldContainer = memo(
	(props: UpdateBeneficialOwnerFieldContainerProps) => {
		const {
			form: { setFieldValue },
			field
		} = props;
		const intl = useIntl();

		const setNumberOfBeneficialOwners = useCallback(
			(number: number) => {
				setFieldValue(props.field.name, number);
			},
			[setFieldValue, props.field.name]
		);

		const updateNumberOfBeneficialowners = useCallback(
			(int: number) => {
				setNumberOfBeneficialOwners(props.field.value + int);
			},
			[setNumberOfBeneficialOwners, props.field.value]
		);

		const [selectedElement, setSelectedElement] = useState<BeneficialOwner | undefined>(undefined); // Used for total share validation

		const validation = createValidationHelpers(intl.locale);
		const namespace = "MONEY_LAUNDERING";

		const { value: useAlternativeEntNrValidation } = useVariableData<boolean>(
			namespace,
			"UseAlternativeEntNrValidation"
		);

		const { value: shortDateFormat } = useVariableData<string>(
			namespace,
			"ShortDateFormat"
		);
		const { value: disableSsnValidation } = useVariableData<boolean>(
			namespace,
			"DisableSsnValidation"
		);

		const { value: disableShareValidation } = useVariableData<boolean>(
			namespace,
			"DisableShareValidation"
		);

		const validateTotalShares = useVariableData<boolean>(
			namespace,
			"ValidateTotalShares"
		).value as any as string === 'true';

		let beneficialOwnerConfig = createBeneficialOwnerConfig(
			intl,
			validation,
			useAlternativeEntNrValidation ?? false,
			shortDateFormat ?? "",
			disableSsnValidation ?? false,
			disableShareValidation ?? false
		);

		const [searchParams, _] = useSearchParams();
		const customerContext = searchParams.get("customerContext");

		const { applicationNumber } = useAppLoad();

		const { value: epId } = useVariableData(namespace, "EpGuid");

		var isFieldVisible = useTemplateFieldIsVisible();

		var includeZip = isFieldVisible({
			name: "IncludeZip",
			namespace: namespace
		});
		var fnameAndLnameForWCService = isFieldVisible({
			name: "FnameAndLnameForWCService",
			namespace: namespace
		});

		const beneficialOwners = useResource(BeneficialOwnerResource.list(), {
			epId,
			includeFirstAndLastname: fnameAndLnameForWCService,
			includeZip,
			applicationNumber,
			customerContext
		});
		
		if (validateTotalShares) {
			const newShareConfig = totalShareValidation(intl, beneficialOwners.reduce((total, bo) => total + Number(bo.share), 0), selectedElement)
			beneficialOwnerConfig = beneficialOwnerConfig.map(config => config.name !== newShareConfig.name ? config : newShareConfig)
		}
		
		useEffect(() => {
			setNumberOfBeneficialOwners(beneficialOwners.length);
		}, [beneficialOwners, setNumberOfBeneficialOwners]);

		const updateBeneficialOwner = useFetcher(BeneficialOwnerResource.update());
		const onUpdateBeneficialOwner = (beneficialOwner: BeneficialOwner) => {
			return updateBeneficialOwner(
				{ id: beneficialOwner.id, epId: epId },
				{ beneficialOwner, customerContext }
			);
		};

		const deleteBeneficialOwner = useFetcher(BeneficialOwnerResource.delete());
		const onDeleteBeneficialOwner = useCallback(
			(beneficialOwner: BeneficialOwner) => {
				updateNumberOfBeneficialowners(-1);
				return deleteBeneficialOwner({
					id: beneficialOwner.id,
					epId: epId,
					customerContext: customerContext
				});
			},
			[
				deleteBeneficialOwner,
				epId,
				updateNumberOfBeneficialowners,
				customerContext
			]
		);

		const createBeneficialOwner = useFetcher(BeneficialOwnerResource.create());
		const onCreateBeneficialOwner = useCallback(
			(beneficialOwner: BeneficialOwner) => {
				const { id, addDate, addUser, ...rest } = beneficialOwner;
				updateNumberOfBeneficialowners(1);
				return createBeneficialOwner(
					{ epId: epId },
					{ beneficialOwner: rest, customerContext },
					[
						[
							BeneficialOwnerResource.list(),
							{
								epId,
								includeFirstAndLastname: fnameAndLnameForWCService,
								includeZip,
								applicationNumber,
								customerContext
							},
							(
								newBeneficialOwnerId: string,
								beneficialOwnerIds: string[] | undefined
							) => [...(beneficialOwnerIds || []), newBeneficialOwnerId]
						]
					]
				);
			},
			[
				createBeneficialOwner,
				epId,
				fnameAndLnameForWCService,
				includeZip,
				applicationNumber,
				updateNumberOfBeneficialowners,
				customerContext
			]
		);

		const onElementSelected = (id: string) => {
			const owner = beneficialOwners.find(owner => owner.id == id);
			setSelectedElement(owner);
		}

		const searchCustomer = useFetcher(CustomerInfoSearchResource.detail());

		const getPersonalInfoFromSsn = useCallback(
			async (beneficialOwner: BeneficialOwner) => {
				const customer = await searchCustomer({
					custNo: beneficialOwner.entNr
				});
				const beneficialOwnerNewInfo =
					mapCustomerResourceToBeneficialOwner(customer);
				const newBeneficialOwner = {
					...beneficialOwner,
					...beneficialOwnerNewInfo
				};
				return newBeneficialOwner as BeneficialOwner;
			},
			[searchCustomer]
		);

		return (
			<UpdateBeneficialOwnerFieldView
				fieldName={field.name}
				beneficialOwners={beneficialOwners}
				beneficialOwnerConfig={beneficialOwnerConfig}
				onCreateBeneficialOwner={onCreateBeneficialOwner}
				onUpdateBeneficialOwner={onUpdateBeneficialOwner}
				onDeleteBeneficialOwner={onDeleteBeneficialOwner}
				onElementSelected={validateTotalShares ? onElementSelected : undefined}
				getPersonalInfoFromSsn={getPersonalInfoFromSsn}
			/>
		);
	}
);

UpdateBeneficialOwnerField.displayName = "UpdateBeneficialOwnerField";
UpdateBeneficialOwnerFieldContainer.displayName =
	"UpdateBeneficialOwnerFieldContainer";

export { UpdateBeneficialOwnerField };
