import { FieldType, TableColumnMetadata } from "./types";
//
export class DTDetailTableSchema {
	static dployTesterContext: string = "DynPrice";
	static metaData: Partial<Record<string, TableColumnMetadata>> = {
		outputInterest: {
			fieldType: FieldType.Percent,
			isEditableFunctions: {
				EditableIfNibor: (form, cells) => {
					const canEdit =
						form.values["dynamicField:DynPrice___FundingInterest"] == null ||
						!isNiborFundingInterestSelected(form.values, cells);
					!canEdit &&
						!isNaN(form.values["dynamicField:OutputInterest"]) &&
						form.setFieldValue("dynamicField:OutputInterest", NaN); //NaN is required to actually remove the value. null/undefined/"" will render "0", however the value will correctly save as null
					return canEdit;
				}
			}
		},
		interestMargin: {
			fieldType: FieldType.Percent,
			isEditableFunctions: {
				EditableIfNotNibor: (form, cells) => {
					const canEdit =
						form.values["dynamicField:DynPrice___FundingInterest"] == null ||
						isNiborFundingInterestSelected(form.values, cells);
					!canEdit &&
						!isNaN(form.values["dynamicField:InterestMargin"]) &&
						form.setFieldValue("dynamicField:InterestMargin", NaN); //NaN is required to actually remove the value. null/undefined/"" will render "0", however the value will correctly save as null
					return canEdit;
				}
			}
		},
		commMarginFixed: {
			fieldType: FieldType.Percent,
			allowNegative: true
		},
		termFee: {
			fieldType: FieldType.Currency
		},
		estFeeTotal: {
			fieldType: FieldType.Currency
		},
		cond___Months___LE: {
			fieldType: FieldType.Number
		}
	};
}

const isNiborFundingInterestSelected = (rowData, cells) =>
	cells
		.find(cell => cell.column.id.includes("FundingInterest"))
		?.column?.optionValues?.some(
			option =>
				option.value?.toLocaleLowerCase().includes("nibor") &&
				rowData["dynamicField:DynPrice___FundingInterest"]?.toString() ===
					option.key?.toString()
		);
