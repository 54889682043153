import { useResource } from "@rest-hooks/core";
import { Typography, Paper, makeStyles, Theme } from "@material-ui/core";
import { CustomerSignatureInfoResource, ID } from "@ploy-lib/rest-resources";

export interface AppSignatureInfoProps {
	applicationNumber: ID;
	hideSignerList?: boolean;
	signerType: number;
}

const useStyles = makeStyles(
	(theme: Theme) => ({
		signerStatusMessage: {},
		customerSignersOuterWrapper: {
			margin: theme.spacing(1),
			padding: theme.spacing(1)
		},
		customerSignersInnerWrapper: {
			"& table": {
				width: "100%"
			}
		}
	}),
	{ name: "DployAppSignatureInfo" }
);

function AppSignatureInfo(props: AppSignatureInfoProps) {
	const { applicationNumber, hideSignerList, signerType } = props;
	const classes = useStyles();
	const customerSignatureInfo = useResource(
		CustomerSignatureInfoResource.detail(),
		!applicationNumber ? null : { applicationNumber, signerType }
	);

	return customerSignatureInfo != null ? (
		<div>
			<Typography className={classes.signerStatusMessage}>
				{customerSignatureInfo.signerStatusMessage}
			</Typography>
			{!hideSignerList && customerSignatureInfo.customerSignersHTML && (
				<Paper className={classes.customerSignersOuterWrapper}>
					<div
						className={classes.customerSignersInnerWrapper}
						dangerouslySetInnerHTML={{
							__html: customerSignatureInfo.customerSignersHTML
						}}
					/>
				</Paper>
			)}
		</div>
	) : null;
}

AppSignatureInfo.displayName = "DployAppSignatureInfo";

export { AppSignatureInfo };
