import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Link,
	makeStyles,
	Theme,
	Typography
} from "@material-ui/core";
import { useTenantImage } from "@ploy-ui/tenants";
import React from "react";
import { SectionProps } from "../Section";
import { addRegisteredSectionLayout } from "../sectionLayoutDescriptions";
import { MarkdownElement } from "@ploy-ui/core";

addRegisteredSectionLayout({
	name: "FrendeInsuranceSection",
	displayName: "FrendeInsuranceSection",
	settings: {}
});

const useStyles = makeStyles((theme: Theme) => ({
	informationImage: {
		backgroundImage: `url(${useTenantImage("FrendeInsuranceImage")})`,
		backgroundSize: "cover",
		backgroundPosition: "50%",
		maxWidth: "12rem",
		minHeight: "15rem",
		flex: "1 1"
	},
	informationSpan: {
		display: "flex"
	},
	informationText: {
		padding: "12px",
		flex: "1 1"
	},
	outerDiv: {
		border: "1px solid #ababab"
	},
	summaryAaccordion: {
		"&.Mui-expanded": {
			margin: "0"
		}
	},
	summaryCollapsed: {
		"&.Mui-expanded": {
			minHeight: 0
		},
		"& .MuiAccordionSummary-content.Mui-expanded": {
			margin: "auto"
		}
	},
	summaryDetails: {
		display: "block",
		padding: "0"
	},
	summaryFooter: {
		backgroundColor: "#ddd",
		paddingTop: "1rem",
		paddingBottom: "1rem",
		paddingRight: "2rem",
		paddingLeft: "2rem"
	},
	summaryMainInformation: {
		paddingTop: "0",
		paddingBottom: "0",
		paddingRight: "2rem",
		paddingLeft: "2rem"
	},
	legalFooter: {
		paddingTop: "0",
		paddingBottom: "0",
		paddingRight: "2rem",
		paddingLeft: "0.5rem"
	}
}));

export const FrendeInsuranceSection = (props: SectionProps) => {
	const classes = useStyles();

	const detailList =
		"- Kaskoforsikring\n- Leiebil i hele reparasjonstiden\n- Totalskadegaranti for bilen inntil 3 år\n- Maskinskade for inntil 8 år gamle biler\n- Batteripakke og elektronisk styringsenhet er også omfattet i maskinskade\n- For elektriske- og hybridbiler er ladekabel inkludert\n- Egenandel 6000 kr";

	return (
		<div className={`${props.className} ${classes.outerDiv}`}>
			<span className={classes.informationSpan}>
				<div className={classes.informationImage} />
				<div className={classes.informationText}>
					<Typography variant="subtitle1">
						<Box sx={{ fontWeight: "bold" }}>
							Gi kunden Frendes beste bilforsikring GRATIS 1 måned
						</Box>
					</Typography>
					<Typography>
						Frende tilbyr knallgode, prisvinnende vilkår, lynrask opptjening av
						bonus og leiebil i hele reparasjonstiden.
					</Typography>
				</div>
			</span>
			<Accordion className={classes.summaryAaccordion}>
				<AccordionSummary className={classes.summaryCollapsed}>
					<Typography>Mer informasjon om forsikringen</Typography>
				</AccordionSummary>
				<AccordionDetails className={classes.summaryDetails}>
					<div className={classes.summaryMainInformation}>
						<Typography variant="subtitle1">
							<Box sx={{ fontWeight: "bold" }}>
								1 måned gratis* bilforsikring fra Frende Forsikring omfatter:
							</Box>
						</Typography>
						<MarkdownElement text={detailList} />
					</div>
					<div className={classes.legalFooter}>
						<Typography variant="subtitle2">
							* kunden må selv betale trafikkforsikringsavgiften.
						</Typography>
					</div>
					<div className={classes.summaryFooter}>
						<Typography variant="subtitle1">
							<Box sx={{ fontWeight: "bold" }}>Lurer du på noe?</Box>
						</Typography>
						<Typography>
							Kontakt Frende på 55 15 36 00 eller{" "}
							<Link
								href="mailto:brage.bilforhandler@frende.no"
								underline="hover"
							>
								brage.bilforhandler@frende.no
							</Link>
						</Typography>
					</div>
				</AccordionDetails>
			</Accordion>
		</div>
	);
};
