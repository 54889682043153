import { apiResourceUrl } from "@ploy-lib/core";
import { BaseCamelCasedResource } from "./BaseResource";

export class CustomerSearchResource extends BaseCamelCasedResource {
	readonly customerId: string;
	readonly customerCompanyNumber: string;
	readonly customerNumber: string;
	readonly customerName: string;
	readonly customerTypeId: number;

	readonly bankAccount: number;
	readonly bankAccountCanBeOverridden: boolean;

	pk() {
		return this.customerId || "";
	}

	static urlRoot = apiResourceUrl("customers");
}

export class PayoutCustomerSearchResource extends CustomerSearchResource {
	static urlRoot = apiResourceUrl("customers/search/payout");
}
