import { Grid, Typography, ThemeProvider } from "@material-ui/core";

import { TemplatePageProps, LayoutPage } from "../../../types";
import { addRegisteredLayout, PanelSettings } from "../layoutDescriptions";
import { Panel } from "../../../panel";
import { DirwebStepper } from "./DirwebStepper";
import { useStyles, actionsTheme, mainTheme } from "./styles";
import { TemplatePanel } from "@ploy-lib/types";
import { usePageState } from "../../../PageContext";
import clsx from "clsx";
import { useTenant } from "@ploy-ui/tenants";

export type DirwebLongPanels = {
	main: TemplatePanel;
	actions: TemplatePanel;
};
export type DirwebLongPanelKeys = keyof DirwebLongPanels;
export type DirwebLongPage = LayoutPage<DirwebLongPanelKeys, DirwebLongPanels>;

const mainPanelSettings: PanelSettings = {
	editableOptions: {
		title: false,
		elevation: true,
		square: true
	}
};
const panelSettings: Record<DirwebLongPanelKeys, PanelSettings> = {
	main: mainPanelSettings,
	actions: {
		editableOptions: {
			desktopViewForMobile: true
		}
	}
};

addRegisteredLayout({
	name: "DirwebLong",
	displayName: "DirwebLong",
	panels: panelSettings,
	pageUri: true,
	pageDescription: true,
	hasHeaderIcon: true
});

export interface DirwebLongProps extends TemplatePageProps<DirwebLongPage> {}

export const DirwebLong = (props: DirwebLongProps) => {
	const { description, panels, stepper, headerIconName } = props;
	const { main, actions } = panels;
	const { step } = usePageState();

	const dirwebLongClasses = useStyles();

	const { tenant } = useTenant();
	const headerIcons = tenant?.formIcons || [];
	const headerIcon = headerIcons.find(icon => icon.name === headerIconName);

	return (
		<>
			<Grid
				container
				className={clsx(dirwebLongClasses.root)}
				justifyContent="center"
			>
				{/* Obsolete! Use stepper setup in FormTemplatePanel  */}
				{!stepper && (
					<Grid item xs={12} md={8}>
						<DirwebStepper step={step} />
					</Grid>
				)}
				{headerIcon && (
					<Grid container item xs={12} md={8} justifyContent="center">
						<img
							src={headerIcon.icon}
							className={dirwebLongClasses.headerIcon}
							alt="Header icon"
						/>
					</Grid>
				)}
				<Grid item xs={12} md={8} className={dirwebLongClasses.header}>
					<Typography
						variant="h3"
						gutterBottom
						className={dirwebLongClasses.titleText}
					>
						{props.pageTitle}
					</Typography>
					<Typography color="textSecondary" variant="body1">
						{description}
					</Typography>
				</Grid>
				{main && (
					<Grid
						item
						xs={12}
						md={8}
						className={clsx(dirwebLongClasses.mainGrid)}
					>
						<Grid item xs={12} md>
							<ThemeProvider theme={mainTheme}>
								<Panel {...main} transparent />
							</ThemeProvider>

							<ThemeProvider theme={actionsTheme}>
								<Panel {...actions} transparent />
							</ThemeProvider>
						</Grid>
					</Grid>
				)}
			</Grid>
		</>
	);
};

DirwebLong.displayName = "DployFormDirwebLong";
