import { ID } from "./util";
import {
	ApplicationStatusId,
	ApplicationStatus,
	DocumentStatus,
	DocumentStatusId
} from "./ApplicationStatus";

export enum EsignType {
	SsnRequired = 0,
	SsnNotRequired = 1,
	SsnNotRequiredCanAdd = 2,
	SsnNotRequiredForVendor = 3
}

export enum CustomerRole {
	NotSet,
	Customer,
	CoSigner,
	Guarantor,
	Supplier,
	Customm,
	LimitCustomer,
	Vendor,
	ResidualValueGuarantor,
	Spouse,
	Cohabitant
}

export interface ClientSettings {
	esignType: EsignType;
	ssnServiceEnabled: boolean;
	canAddAndDeleteVendorSigners: boolean;
	canChooseSigners: boolean;
	canEditIndividualDocumentsForSigners: boolean;
	disableVendorSigner: boolean;
	hideVendorSigner: boolean;
	disableSupplierSigner: boolean;
	hideSupplierSigner: boolean;
	disableSignerEditing: boolean;
	externalUserCanNotAddSigners: boolean;
	allowEmptyEmail: boolean;
	showValidPeriod: boolean;
	canChangeObject: boolean;
	distributionValues: Record<string, string>;
	alwaysShowProductName: boolean;
	mustSignDefaultChecked: boolean;
	showProductInfo: boolean;
	requireRegnrForEsign: boolean;
	signersMustHaveContactInfo: boolean;
	hideSigningActions: boolean;
	hideDocumentsToSign: boolean;
	showLastUpdateTime: boolean;
	useStandardLayoutForDirectUsers: boolean;
	useIndividualSignerStatus: boolean;
}

export interface AppLoad {
	readonly id?: string;
	readonly applicationNumber?: string;
	readonly isDirectUser?: boolean;
	readonly productExternalCode: string;
	readonly productInfoHeader: string;
	readonly productName: string;
	readonly productTypeId: ID;
	readonly applicationStatusId: ApplicationStatusId;
	readonly documentStatusId: DocumentStatusId;
	readonly isEsigned: boolean;
	readonly applicationStatus: ApplicationStatus;
	readonly documentStatus: DocumentStatus;
	readonly applicationId: number;
	readonly documentStatusDescription: string;
	readonly applicationStatusDescription: string;
	readonly vendorInfo?: {
		contactPhone: string;
		contactEmail: string;
		fullName: string;
		salesChannel: string;
	};
	readonly coSignerId?: number;
	readonly loggedInCustomerRole: CustomerRole;
	readonly vulcanContext: string;
	readonly settings: ClientSettings;
	readonly isScored: boolean;
	readonly externalRedirectUrl: string;
	readonly sentToEsignature: string;
	readonly isSentToEsignatureByInternal: boolean;
	readonly equityStatus: number;
	readonly productId: number;
}
