import { makeStyles, Theme } from "@material-ui/core/styles";
import { usePages } from "../../pagesContext";
import { addRegisteredStepper } from "./stepperDescriptions";
import { BaseStepperProps } from "./BaseStepperProps";
import { usePageState } from "../../PageContext";

const useStepperXofYStyles = makeStyles((theme: Theme) => ({
	circle: {
		borderRadius: "50%",
		justifyContent: "center",
		alignItems: "center",
		display: "flex",
		color: theme.palette.text.primary,
		backgroundColor: theme.palette.secondary.main,
		boxSizing: "border-box",
		mozBoxSizing: "border-box",
		webkitBoxSizing: "border-box",
		textAlign: "center",
		width: 85,
		height: 85,
		margin: "0 auto"
	},
	stepperCircleInnerText: {
		fontWeight: "bold"
	},
	pageIndex: {
		fontSize: 32
	},
	pageCount: {
		fontSize: 20
	}
}));

addRegisteredStepper({
	name: "XofYStepper",
	displayName: "XofYStepper"
});

export const XofYStepper = (props: BaseStepperProps) => {
	const pages = usePages();
	const { step } = usePageState();

	var count = -1;

	const displayIndexArr: number[] = pages.map(p => {
		if (p.displayInStepper) {
			count += 1;
			return count;
		}
		return -1;
	});

	const getDisplayNum = (step: number) => {
		if (step === 0 && displayIndexArr[step] === -1) {
			return -1;
		}
		return displayIndexArr[step] === -1
			? getDisplayNum(step - 1)
			: displayIndexArr[step];
	};

	const activeStep = getDisplayNum(step);

	const xOfYClasses = useStepperXofYStyles();

	return activeStep > -1 ? (
		<div className={xOfYClasses.circle}>
			<p className={xOfYClasses.stepperCircleInnerText}>
				<span className={xOfYClasses.pageIndex}>{activeStep + 1}</span>
				<span className={xOfYClasses.pageCount}>
					{" "}
					/ {displayIndexArr.filter(x => x >= 0).length}
				</span>
			</p>
		</div>
	) : null;
};
