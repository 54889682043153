import {
	DployFormControl,
	getFieldError,
	identityRecordOfFieldEditorOptions
} from "@ploy-ui/form-fields";
import {
	ButtonFieldProps,
	commonButtonColors,
	commonButtonVariants,
	isButtonColor,
	isButtonVariant
} from "./ButtonCommon";
import { apiResourceUrl, getIn } from "@ploy-lib/core";
import icons from "./Icons";
import { useAppLoad } from "../../appLoadContext";
import { useSearchParams } from "react-router-dom";
import { PendingButton } from "@ploy-ui/core";
import { useCallback, useState } from "react";
import { Box, FormHelperText } from "@material-ui/core";

export const GoToSblButton = ({
	muiEndIcon,
	variant,
	color,
	size,
	action,
	fullWidth,
	margin,
	options: { style },
	errorDisplay,
	disabled,
	buttonSize,
	className,
	field,
	form,
	label,
	...props
}: ButtonFieldProps) => {
	const appLoadData = useAppLoad();
	const [searchParams, _] = useSearchParams();
	const customerContext = searchParams.get("customerContext");

	const { value, name } = field;
	const { errors, touched } = form;

	const errorProps = getFieldError(errors, touched, field.name, errorDisplay);
	const error = getIn(form.errors, name);

	const buttonColor = isButtonColor(color) ? color : undefined;
	const buttonVariant = isButtonVariant(variant) ? variant : undefined;

	const [internalState, setInternalState] = useState<{
		loading?: boolean;
		success?: boolean;
		error?: Error;
	}>({});

	const onClick = useCallback(
		async (e: React.MouseEvent<Element>) => {
			try {
				form.setFieldTouched(name, true);
				if (error) return;
				setInternalState({ loading: true, success: false });

				let goToSblUri = apiResourceUrl(
					`/GoToSbl/${appLoadData.applicationId}/`
				);

				if (customerContext) {
					goToSblUri += `?customerContext=${customerContext}`;
				}

				const response = await fetch(goToSblUri, { method: "GET" });

				if (!response.ok) {
					throw new Error(
						"Error initiating SBL: Response from server is not OK"
					);
				}

				const content = (await response.json()) as {
					success?: boolean;
					redirectUrl?: string;
				};

				if (!content.success) {
					throw new Error("Error initiating SBL: Missing SBL redirect URL");
				}

				window.open(content.redirectUrl, "_parent");
				setInternalState({ loading: false, success: true });
			} catch (error: any) {
				console.error(error);
				setInternalState({ error: error, loading: false });
			}
		},
		[form, name, error, appLoadData.applicationId, customerContext]
	);

	const helperText =
		(internalState.success && value) ||
		(errorProps?.error && errorProps.helperText) ||
		internalState.error?.message;

	return (
		<DployFormControl
			className={className}
			margin={margin as any}
			error={errorProps?.error || Boolean(internalState.error)}
			fullWidth={fullWidth}
		>
			<PendingButton
				style={style}
				color={buttonColor}
				variant={buttonVariant}
				fullWidth={fullWidth}
				disabled={disabled || errorProps?.error}
				helperText={errorProps?.helperText}
				size={buttonSize || "large"}
				endIcon={muiEndIcon && icons[muiEndIcon]}
				success={internalState.success && !errorProps?.error}
				error={errorProps?.error || Boolean(internalState.error)}
				onClick={onClick}
			>
				{label}
			</PendingButton>
			{helperText && (
				<Box
					component={FormHelperText}
					textAlign="center"
					color={
						errorProps?.error || Boolean(internalState.error)
							? "error.main"
							: internalState.success
							? "success.main"
							: undefined
					}
				>
					{helperText}
				</Box>
			)}
		</DployFormControl>
	);
};

GoToSblButton.displayName = "GoToSblButton";

export const EditorGoToSblButton = identityRecordOfFieldEditorOptions({
	GoToSblButton: {
		editableOptions: {
			variant: commonButtonVariants,
			color: commonButtonColors,
			muiEndIcons: Object.keys(icons)
		}
	}
});
