import React, { memo } from "react";
import {
	BeneficialOwnerResource,
	BeneficialOwner
} from "@ploy-lib/rest-resources";
import { ExpandableListEditSingle, ConfigElement } from "@ploy-ui/core";
import { FormHelperText, Grid, ListItemText } from "@material-ui/core";
import { ChevronRight } from "@material-ui/icons";

import { ErrorMessage } from "formik";
import { FormattedMessage } from "react-intl";

const collapsedElement = (values: BeneficialOwner) => (
	<Grid container alignItems="center">
		<ChevronRight />
		<ListItemText
			primary={`${values.firstname} ${values.lastname}`}
			secondary={`${values.share} %`}
		/>
	</Grid>
);

export interface UpdateBeneficialOwnerFieldProps {
	fieldName: string;
	beneficialOwners: BeneficialOwner[];
	beneficialOwnerConfig: ConfigElement<BeneficialOwner>[];
	onCreateBeneficialOwner: (beneficialOwner: BeneficialOwner) => any;
	onUpdateBeneficialOwner: (beneficialOwner: BeneficialOwner) => any;
	onDeleteBeneficialOwner: (beneficialOwner: BeneficialOwner) => any;
	onElementSelected?: (beneficialOwnerId: string) => any;
	getPersonalInfoFromSsn: (
		beneficialOwner: BeneficialOwner
	) => Promise<BeneficialOwner>;
}
/**
 * @param fieldName FieldNAme
 * @param beneficialOwners list of beneficialOwners
 * @param beneficialOwnerConfig config for expandableListEditSingle
 * @param onCreateBeneficialOwner function for creating beneficialOwner
 * @param onUpdateBeneficialOwner function for updating existing beneficialOwner
 * @param onDeleteBeneficialOwner function for deleting existing beneficialOwner
 * @param onElementSelected function for handling id of a selected element
 * @param getPersonalInfoFromSsn function for getting personal info form ssn.
 */
const UpdateBeneficialOwnerFieldView = memo(
	(props: UpdateBeneficialOwnerFieldProps) => {
		const {
			fieldName,
			beneficialOwners,
			beneficialOwnerConfig,
			onCreateBeneficialOwner,
			onUpdateBeneficialOwner,
			onDeleteBeneficialOwner,
			onElementSelected = undefined,
			getPersonalInfoFromSsn
		} = props;		

		const addBtnLabel = <FormattedMessage
								id="template-form.beneficial-owner.add_button"
								description="Label for add beneficial owner button"
								defaultMessage="Add beneficial owner"
							/>

		return (
			<Grid xs={12}>
				<ExpandableListEditSingle<BeneficialOwner>
					initialElementsList={beneficialOwners}
					elementIdKey={"id"}
					elementConfig={beneficialOwnerConfig}
					onCreate={onCreateBeneficialOwner}
					onUpdate={onUpdateBeneficialOwner}
					onDelete={onDeleteBeneficialOwner}
					onElementSelected={onElementSelected}
					elementCreator={() => BeneficialOwnerResource.fromJS({})}
					collapsedElement={collapsedElement}
					margin="dense"
					useThemedButtons
					canDelete
					autofillFunction={getPersonalInfoFromSsn}
					columns={2}
					addBtnLabel={addBtnLabel}
					showErrorMessages={true}
				/>

				<ErrorMessage
					name={fieldName}
					render={errorMessage => (
						<FormHelperText error>{errorMessage}</FormHelperText>
					)}
				/>
			</Grid>
		);
	}
);

UpdateBeneficialOwnerFieldView.displayName = "UpdateBeneficialOwnerFieldView";

export { UpdateBeneficialOwnerFieldView };
