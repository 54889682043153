import { apiResourceUrl } from "@ploy-lib/core";
import paramsToString, { BaseCamelCasedResource } from "./BaseResource";

export interface ICustomerBankAccount {
	readonly customerBankAccountId: string;
	readonly customerNumber: string;
	readonly accountNumber: string;
	readonly customerName: string;
	readonly isValid: boolean;
	readonly user: string;
	readonly dateChecked: Date;
	readonly message: string;
	readonly applicationId: number | undefined;
}

export class CustomerBankAccount
	extends BaseCamelCasedResource
	implements ICustomerBankAccount
{
	readonly customerBankAccountId: string = "";
	readonly customerNumber: string;
	readonly accountNumber: string;
	readonly customerName: string;
	readonly isValid: boolean;
	readonly user: string;
	readonly dateChecked: Date;
	readonly message: string;
	readonly applicationId: number | undefined;

	pk() {
		return this.customerBankAccountId;
	}

	static listUrl(
		allParams: Readonly<Record<string, string | number>> = {}
	): string {
		const { applicationId, accountNumber, customerNumber } = allParams;

		if (applicationId !== undefined) {
			if (customerNumber !== undefined && accountNumber !== undefined) {
				return `${this.urlRoot}/${customerNumber}/${accountNumber}/${applicationId}`;
			}

			return `${this.urlRoot}/${applicationId}/application`;
		}

		if (customerNumber !== undefined && accountNumber !== undefined) {
			return `${this.urlRoot}/${customerNumber}/${accountNumber}`;
		}

		throw new Error("CustomerBankAccountResource requires applicationId");
	}

	static urlRoot = apiResourceUrl("CustomerBankAccount/");

	static urlTemplates = [`${this.urlRoot}/{applicationId}`];
}
