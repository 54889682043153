import { useState } from "react";
import Section, { SectionProps } from "../Section";
import { Grid, Typography, Button } from "@material-ui/core";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { addRegisteredSectionLayout } from "../sectionLayoutDescriptions";
import {
	FormattedMessage,
	injectIntl,
	WrappedComponentProps
} from "react-intl";

import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";

const useStyles = makeStyles(
	theme => ({
		fullWidth: {
			width: "100%"
		},
		header: {
			alignItems: "center",
			display: "flex"
		},
		editButton: {}
	}),
	{ name: "DployLockAndEditableSection" }
);

addRegisteredSectionLayout({
	name: "LockAndEditableSection",
	displayName: "LockAndEditableSection",
	settings: {
		editableOptions: {
			editButtonAtBottom: true,
			hideEditAndSaveIcons: true
		}
	}
});

const LockAndEditableSection = injectIntl(
	(props: SectionProps & WrappedComponentProps) => {
		const {
			className,
			onClick,
			sectionTitle,
			editButtonAtBottom,
			hideEditAndSaveIcons
		} = props;
		const classes = useStyles();

		const [sectionIsEditable, setSectionIsEditable] = useState(false);

		const IconButton = sectionIsEditable ? SaveIcon : EditIcon;

		const editButton = (
			<Button
				onClick={() => setSectionIsEditable(!sectionIsEditable)}
				color={"secondary"}
				endIcon={!hideEditAndSaveIcons && <IconButton />}
				className={classes.editButton}
			>
				{sectionIsEditable ? (
					<FormattedMessage
						id="template-form.section.lockAndEditableSection.save"
						defaultMessage="Save"
					/>
				) : (
					<FormattedMessage
						id="template-form.section.lockAndEditableSection.edit"
						defaultMessage="Edit"
					/>
				)}
			</Button>
		);

		return (
			<Grid
				container
				spacing={3}
				className={clsx(className)}
				onClick={onClick}
				justifyContent="center"
			>
				<Grid
					container
					direction="row"
					justifyContent="space-between"
					className={classes.header}
				>
					<Grid item>
						<Typography variant="h5">{sectionTitle}</Typography>
					</Grid>
					{!editButtonAtBottom && <Grid item>{editButton}</Grid>}
				</Grid>
				<Section
					{...props}
					sectionTitle={undefined}
					literal={!sectionIsEditable}
				/>
				{editButtonAtBottom && editButton}
			</Grid>
		);
	}
);

LockAndEditableSection.displayName = "DployLockAndEditableSection";

export { LockAndEditableSection };
