import { apiResourceUrl } from "@ploy-lib/core";
import { AbstractInstanceType, Resource } from "@rest-hooks/rest";
import { v4 as uuidv4 } from "uuid";
import { BaseCamelCasedResource } from "./BaseResource";

export interface BeneficialOwner {
	readonly id: string | null;
	readonly __id: string;
	readonly entNr: string | null;
	readonly firstname: string | null;
	readonly lastname: string | null;
	readonly name: string | null;
	readonly address: string | null;
	readonly addressCity: string | null;
	readonly addressZip: string | null;
	readonly share: string | null;
	readonly addUser: string | null;
	readonly addDate: string | null;
	readonly customerId: number | null;
}
export class BeneficialOwnerResource
	extends BaseCamelCasedResource
	implements BeneficialOwner
{
	readonly id: string | null = null;
	readonly __id: string = this.id ?? uuidv4();
	readonly entNr: string | null;
	readonly firstname: string | null;
	readonly lastname: string | null;
	readonly name: string | null;
	readonly address: string | null;
	readonly addressCity: string | null;
	readonly addressZip: string | null;
	readonly share: string | null;
	readonly addUser: string | null;
	readonly addDate: string | null;
	readonly customerId: number | null;

	pk() {
		return `${this.id || this.__id}`;
	}

	static url<T extends typeof Resource>(
		this: T,
		urlParams: { epId: string, customerId?: number, saveApplication?: boolean } & Partial<AbstractInstanceType<T>>
	): string {
		if (urlParams) {
			let body = urlParams.customerId ? `customerId=${urlParams.customerId}&` : ''
			body += urlParams.saveApplication ? `saveApplication=${urlParams.saveApplication}` : ''
			if (this.pk(urlParams) !== undefined) {
				if (this.urlRoot.endsWith("/")) {
					return `${this.urlRoot}${urlParams.epId}/${this.pk(urlParams)}?${body}`;
				}
				return `${this.urlRoot}/${urlParams.epId}/${this.pk(urlParams)}?${body}`;
			}
		}
		return this.urlRoot;
	}

	static listUrl(searchParams: { epId: string }): string {
		if (searchParams && Object.keys(searchParams).length) {
			const { epId, ...realSearchParams } = searchParams;
			const params = new URLSearchParams(realSearchParams as any);
			params.sort();

			if (this.urlRoot.endsWith("/")) {
				return `${this.urlRoot}${epId}?${params.toString()}`;
			}
			return `${this.urlRoot}/${epId}?${params.toString()}`;
		}
		return this.urlRoot;
	}

	static fromCreditService<T extends typeof BeneficialOwnerResource>(this: T) {
		const endpoint = this.list();
		return endpoint.extend({
			url: (params: any) =>
				this.listUrl(params).replace(
					this.urlRoot,
					`${this.urlRoot}/fromCreditService`
				),
			method: "post"
		});
	}

	static noBeneficialOwners<T extends typeof BeneficialOwnerResource>(this: T) {
		const endpoint = this.list();
		return endpoint.extend({
			url: (params: any) =>
				this.listUrl(params).replace(
					this.urlRoot,
					`${this.urlRoot}/noBeneficialOwners`
				),
			method: "post"
		});
	}

	static urlRoot = apiResourceUrl("BeneficialOwner");
}
