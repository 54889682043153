import FinnishSSN from "finnish-ssn";
import personnummer from "personnummer.js";
import { IUtils } from "@date-io/core/IUtils";

export interface ValidationHelpers {
	isLengthOfSSN(value?: string): boolean;
	validOrgNumber(value?: string): boolean;
	validSsn(value?: string): boolean;
	isValidNorwegianSSN(value?: string): boolean;
	phoneValidation(value?: string): boolean;
	phoneHomeValidation(value?: string): boolean;
	emailValidation(value?: string): boolean;
}

const phonePatterns: Record<string, RegExp> = {
	se: /^07((([0-9]{2})[(-s)]?([0-9]{6}))|(([0-9]{1})[(-s)]?([0-9]{7})))$/,
	no: /(^[0-9]{8}$)|(^[0-9]{3} [0-9]{2} [0-9]{3}$)/,
	dk: /^([0-9]{8})$/,
	fi: /^[0-9]{7,12}$/
};

const phoneHomePatterns: Record<string, RegExp> = {
	...phonePatterns,
	se: /^0([0-9]{1,3}[-]?[0-9]{2,3} ?[0-9]{1,3} ?[0-9]{2})$/,
	no: /(^[0-9]{8}$)|(^[0-9]{3} [0-9]{2} [0-9]{3}$)|(^[0-9]{2} [0-9]{2} [0-9]{2} [0-9]{2}$)/
};

var emailPattern = /\S+@\S+\.\S+/;

export function createValidationHelpers<TDate>(
	locale: string = "nb-NO",
	dateUtils?: IUtils<TDate>
): ValidationHelpers {
	const [localeLang, localeCountry = localeLang] = locale.split("-", 2);

	const countryCode = localeCountry.substring(0, 2).toLowerCase();

	function isLengthOfSSN(value?: string) {
		switch (localeLang) {
			case "sv":
				if (!value || value.length !== 10) return false;
				break;
			case "nb":
				if (!value || value.length !== 11) return false;
				break;
		}
		return true;
	}

	function validSsn(value?: string) {
		if (!value) return false;
		value = value.replace(/\s/g, "");

		switch (localeLang) {
			case "fi":
				if (value === "Kirjattu") return true;
				break;
			case "en":
				if (value === "Registered") return true;
				break;
			case "sv":
				if (value === "Registrerad") return true;
				break;
			case "dk":
				if (value === "Registreret") return true;
				break;
			case "nb":
			default:
				if (value === "Registered" || value === "Registrert") return true;
		}

		switch (countryCode) {
			case "fi":
				return FinnishSSN.validate(value);
			case "se":
				return value.length < 12 && personnummer.validate(value);
			case "dk":
				return value.length == 10;
			case "no":
			default:
				return isValidNorwegianSSN(value);
		}
	}

	function isValidNorwegianSSN(ssn?: string) {
		if (!ssn || !ssn.match("^[0-9]{11}$")) {
			return false;
		}

		const calculateCheckSumMod11 = function (ssn: string, factors: number[]) {
			let sum = 0;
			for (let i = 0; i < factors.length; ++i) {
				sum += parseInt(ssn.charAt(i), 10) * factors[i];
			}
			const checkSum = 11 - (sum % 11);
			return checkSum === 11 ? 0 : checkSum;
		};

		return (
			calculateCheckSumMod11(ssn, [3, 7, 6, 1, 8, 9, 4, 5, 2]) ===
				parseInt(ssn.charAt(9), 10) &&
			calculateCheckSumMod11(ssn, [5, 4, 3, 2, 7, 6, 5, 4, 3, 2]) ===
				parseInt(ssn.charAt(10), 10)
		);
	}

	function validOrgNumber(value?: string) {
		if (value == null) return false;
		value = value.replace(/\s/g, "");
		switch (countryCode) {
			case "fi":
				return value.length === 8;
			case "se":
				return personnummer.validateCIN(value);
			case "dk":
				return value.length === 8;
			case "no":
			default:
				return value.length === 9;
		}
	}

	function phoneValidation(value?: string) {
		if (!value) return false;

		const pattern = phonePatterns[countryCode] ?? phonePatterns.no;

		return value.match(pattern) != null;
	}

	function phoneHomeValidation(value?: string) {
		if (!value) return false;
		const pattern = phoneHomePatterns[countryCode] ?? phoneHomePatterns.no;

		return value.match(pattern) != null;
	}

	function emailValidation(value?: string) {
		if (!value) return false;
		return value.match(emailPattern) != null;
	}

	return {
		isLengthOfSSN,
		validOrgNumber,
		validSsn,
		isValidNorwegianSSN,
		phoneValidation,
		phoneHomeValidation,
		emailValidation
	};
}
