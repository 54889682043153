import React, { useState } from "react";
import { Grid, makeStyles, Paper, Theme } from "@material-ui/core";
import { addRegisteredSectionLayout } from "../sectionLayoutDescriptions";
import { useCalculationFields, Field } from "@ploy-lib/calculation";
import clsx from "clsx";
import { SectionProps } from "../Section";
import { FormHelperTextContainerWrapper, HelperTextIconButton } from "../..";
import { MarkdownElement, useFormatRuntimeMessage } from "@ploy-ui/core";
import { useTenant, useTenantImage } from "@ploy-ui/tenants";
import { FormattedNumber, useIntl } from "react-intl";

addRegisteredSectionLayout({
	name: "MarkdownSection",
	displayName: "MarkdownSection",
	settings: {
		editableOptions: {
			mainText: true,
			standardMargin: true,
			centerText: true,
			helperText: true
		}
	}
});

const useStyles = makeStyles(
	(theme: Theme) => ({
		root: {
			margin: "0 auto",
			backgroundColor: "inherit"
		},
		standardMargin: {
			maxWidth: "550px"
		},
		centerText: {
			textAlign: "center",
			justifyContent: "center"
		},
		outline: ({ outline, square }: SectionProps) => ({
			border: `1px solid ${
				(outline && theme.palette[outline] && theme.palette[outline].main) ||
				theme.palette.secondary.main
			}`
		}),
		outlineWidth: ({ outlineWidth }: SectionProps) => ({
			borderWidth: outlineWidth
		}),
		body: {
			"& > p": {
				marginBlockStart: `${theme.spacing(0.5)}px`,
				marginBlockEnd: `${theme.spacing(0.5)}px`,
				lineHeight: "24px",
				fontSize: theme.typography.body1.fontSize
			},
			"& > h4": {
				fontWeight: theme.typography.h4.fontWeight,
				fontSize: theme.typography.h4.fontSize,
				marginBlockStart: `${theme.spacing(1)}px`,
				marginBlockEnd: `${theme.spacing(1)}px`
			},
			"& > h6": {
				fontWeight: theme.typography.h6.fontWeight,
				fontSize: theme.typography.h6.fontSize,
				marginBlockStart: `${theme.spacing(0.75)}px`,
				marginBlockEnd: `${theme.spacing(0.75)}px`
			}
		}
	}),
	{ name: "DployMarkdownSection" }
);

interface MarkdownSectionProps extends SectionProps {
	mainText?: string;
	standardMargin?: boolean;
	centerText?: boolean;
	helperText?: string;
}

export function MarkdownSection(props: MarkdownSectionProps) {
	const {
		mainText = "",
		standardMargin = false,
		centerText = false,
		allFields = [],
		className,
		onClick,
		helperText = "",
		elevation = 0,
		outline,
		outlineWidth,
		fullWidth
	} = props;
	const { formatNumber } = useIntl();
	const { tenant } = useTenant();
	const images = tenant?.images ?? {};
	const firstImages = {};
	Object.keys(images).forEach(key => {
		firstImages[key] = images[key][0];
	});

	const calculationFields = useCalculationFields(
		...allFields.map(f => ({ name: f.name, namespace: f.namespace } as Field))
	);

	const mainTextValues = calculationFields.reduce((acc, field, index) => {
		const value =
			field?.value?.toString() ?? field?.fieldValue?.toString() ?? "";
		if (allFields[index].renderAs === "CurrencyField")
			acc[allFields[index].name] = formatNumber(Number(value), {
				format: "currency"
			});
		else if (allFields[index].renderAs === "PercentField")
			acc[allFields[index].name] = formatNumber(Number(value), {
				format: "percent"
			});
		else acc[allFields[index].name] = value;
		return acc;
	}, {});

	const formatMessage = useFormatRuntimeMessage();

	const message = formatMessage(
		mainText,
		{ ...mainTextValues, ...firstImages },
		{
			ignoreTag: true
		}
	);

	const classes = useStyles(props);
	const [showHelperText, setShowHelperText] = useState(false);

	return (
		<Grid
			component={Paper}
			elevation={elevation}
			className={clsx(className, classes.root, {
				[classes.standardMargin]: standardMargin,
				[classes.centerText]: centerText,
				[classes.outline]: outline,
				[classes.outlineWidth]: outlineWidth
			})}
			onClick={onClick}
			xs={12}
			item
			container
		>
			<Grid item xs={fullWidth ? 12 : 11}>
				<MarkdownElement text={message} />
			</Grid>
			{helperText && (
				<HelperTextIconButton
					toggleHelperText={() => setShowHelperText(display => !display)}
					showHelperText={showHelperText}
				/>
			)}
			<Grid item container xs={12}>
				{showHelperText && helperText ? (
					<FormHelperTextContainerWrapper helperText={helperText} />
				) : null}
			</Grid>
		</Grid>
	);
}
