import { useRef, useEffect } from "react";
import { FormikContextType } from "formik";
import { isEqual } from "@ploy-lib/calculation";
//
export function useMergeUpdateValues<TN extends string, TD>(
	initialValues: Partial<Record<TN, Partial<Record<string, TD>>>> | undefined,
	formik: FormikContextType<typeof initialValues>,
	clear: string[] = [],
	fieldsWithIgnoreCgfHandling: Record<string, Record<string, boolean>> = {}
) {
	const { setValues } = formik;

	const originalValuesRef = useRef(initialValues);
	const initialValuesRef = useRef(initialValues);
	const formValuesRef = useRef(formik.values);
	useEffect(() => {
		if (initialValuesRef.current !== initialValues) {
			const originalValues = originalValuesRef.current;
			const lastValues = formValuesRef.current;

			if (initialValues && lastValues) {
				// Merge changes
				const mergeValues: Partial<Record<TN, Partial<Record<string, TD>>>> =
					{};

				let namespace: string;
				for (namespace in lastValues) {
					// Don't add back namespaces that have been removed
					if (!initialValues.hasOwnProperty(namespace)) continue;

					const originalNamespace =
						originalValues && originalValues[namespace as string];

					const initialNamespace =
						initialValues && initialValues[namespace as string]!;

					if (originalNamespace === initialNamespace) continue;

					if (clear.includes(namespace)) continue;

					let field: string;
					for (field in lastValues[namespace]) {
						//Do not clear fields that have ignoreCgfHandling set to true
						if (fieldsWithIgnoreCgfHandling[namespace]?.[field]) {
							if (!mergeValues.hasOwnProperty(namespace))
								mergeValues[namespace] = {};
							mergeValues[namespace][field] = lastValues[namespace][field];
							continue;
						}

						// Don't add back fields that have been removed
						if (!initialNamespace.hasOwnProperty(field)) continue;

						if (isEqual(lastValues[namespace][field], initialNamespace[field]))
							continue;

						// Ignore untouched fields
						if (
							originalNamespace &&
							isEqual(lastValues[namespace][field], originalNamespace[field])
						)
							continue;

						if (!mergeValues.hasOwnProperty(namespace))
							mergeValues[namespace] = { ...initialNamespace };

						// Set the new value to the previous value
						mergeValues[namespace][field] = lastValues[namespace][field];
					}
				}
				mergeValues["AdditionalSubmitValues"] =
					lastValues["AdditionalSubmitValues"];

				// newValues = initialValues overridden by changes from mergeValues
				let newValues = initialValues;
				for (namespace in mergeValues) {
					if (newValues[namespace])
						newValues[namespace] = {...newValues[namespace], ...mergeValues[namespace]};
					else
						newValues[namespace] = mergeValues[namespace];
				}

				setValues(newValues);
				formValuesRef.current = newValues;
			}

			initialValuesRef.current = initialValues;
		}
	}, [setValues, initialValues, clear]);

	useEffect(
		() => void (formValuesRef.current = formik.values),
		[formik.values]
	);
}
