import React, { useMemo, memo, useEffect } from "react";

import { useIntl } from "react-intl";
import { templateToPages } from "./templateToPages";
import { FormPages } from "./page/FormPages";
import { MissingPage } from "./page";
import { usePageState, PageStateProvider } from "./PageContext";
import { FormTemplate, TemplatePage } from "@ploy-lib/types";
import { PagesProvider } from "./pagesContext";
import { RedirectToFirstError } from "./components/RedirectToErrorOnFirstMount";
import { useAppLoad } from "./appLoadContext";
import { Grid } from "@material-ui/core";
import { ProductInfo } from "./components/ProductInfo";
import { FormTemplateProvider } from "./FormTemplateContext";

export interface TemplateFormProps {
	error?: Error | string | boolean;
	loading?: boolean;
	template?: FormTemplate<TemplatePage>;
	header?: React.ReactNode;
	independent?: boolean;
	skipOwnPageStateProvider?: boolean;
	canSubmit?: boolean;
	modalClick?: number;
	modalCloseMode?: number;
}

export const TemplateForm = memo(
	({
		template,
		independent,
		header,
		loading,
		error,
		skipOwnPageStateProvider,
		canSubmit = false,
		modalClick,
		modalCloseMode
	}: TemplateFormProps) => {
		const intl = useIntl();

		const appData = useAppLoad();

		const pages = useMemo(
			() => (template ? templateToPages(intl, template, canSubmit) : []),
			[template, canSubmit, intl]
		);

		if (process.env.NODE_ENV === "development") {
			// eslint-disable-next-line react-hooks/rules-of-hooks
			useEffect(() => {
				(window as any).__dploy = {
					...(window as any).__dploy,
					template,
					pages
				};
			}, [template, pages]);
		}

		const { labels } = usePageState();

		if (pages.length === 0)
			return <MissingPage header={header} loading={loading} error={error} />;

		const templatePageLabels = pages.map(p =>
			(p.pageUri?.trim().length ?? 0) > 0 ? p.pageUri : p.pageTitle
		);

		const alreadyProvided =
			templatePageLabels.length === labels.length &&
			templatePageLabels.every((l, i) => l === labels[i]);

		const hasMultiplePages = templatePageLabels.length > 1;

		const shouldProvidePageState = hasMultiplePages
			? independent || !alreadyProvided // Ensure page state is provided
			: independent && alreadyProvided; // Override page state if needed

		const productHeader = appData.settings.showProductInfo ? (
			<Grid container spacing={2}>
				<Grid item xs={12}>
					{header}
				</Grid>
				<ProductInfo
					header={appData.productInfoHeader}
					productExternalCode={appData.productExternalCode}
				/>
			</Grid>
		) : (
			header
		);
		const formPages = (
			<PagesProvider value={pages}>
				<FormPages
					header={productHeader}
					pages={pages}
					modalClick={modalClick}
					modalCloseMode={modalCloseMode}
					stepper={template?.stepper}
				/>
				<RedirectToFirstError />
			</PagesProvider>
		);

		let childrenToRender;
		if (shouldProvidePageState && !skipOwnPageStateProvider) {
			childrenToRender = (
				<PageStateProvider labels={templatePageLabels}>
					{formPages}
				</PageStateProvider>
			);
		} else {
			childrenToRender = formPages;
		}
		return (
			<FormTemplateProvider
				value={{ formTemplateId: template?.formTemplateId as number }}
			>
				{childrenToRender}
			</FormTemplateProvider>
		);
	}
);
