import { FieldType, TableColumnMetadata } from "./types";

interface LienInterface {
	readonly creditorOrgNo: string;
	readonly creditorName: string;
	readonly registrationDate: string;
	readonly priority: string;
	readonly yieldPriority: boolean;
	readonly documentId: string;
	readonly value: number;
	readonly writtenDownValue: number;
}

export class LienSchema {
	static metaData: Partial<Record<keyof LienInterface, TableColumnMetadata>> = {
		creditorOrgNo: {
			fieldType: FieldType.Text
		},
		creditorName: {
			fieldType: FieldType.Text
		},
		registrationDate: {
			fieldType: FieldType.Date
		},
		priority: {
			fieldType: FieldType.Number
		},
		yieldPriority: {
			fieldType: FieldType.Checkbox,
			alwaysEditable: true
		},
		documentId: {
			fieldType: FieldType.Text
		},
		value: {
			fieldType: FieldType.Currency
		},
		writtenDownValue: {
			fieldType: FieldType.Currency,
			alwaysEditable: true
		}
	};
}
