import { ID, DployImage } from "@ploy-lib/types";
import { apiResourceUrl, isNotNull } from "@ploy-lib/core";
import { BaseResource } from "./BaseResource";
import {
	RestEndpoint,
	SchemaList,
	AbstractInstanceType
} from "@rest-hooks/rest";

const mapImageUrlToResource = (fileUrl: string) => {
	const [filename, type] = fileUrl.split("/").reverse();
	return {
		fileUrl,
		filename,
		type
	};
};

interface ImageParams {
	id?: ID;
	type?: string;
}

export class ImageResource extends BaseResource implements DployImage {
	readonly id?: ID;
	readonly filename: string = "";
	readonly file: File | null = null;
	readonly fileUrl: string = "";

	pk() {
		return this.fileUrl;
	}

	static urlRoot = apiResourceUrl("styles");
	static urlTemplates = [
		`${ImageResource.urlRoot}/{id}/images/{type}`,
		`${ImageResource.urlRoot}/{id}/images`
	];

	static upload<T extends typeof ImageResource>(
		this: T
	): RestEndpoint<
		(this: RestEndpoint, params: ImageParams, body?: File[]) => Promise<any>,
		SchemaList<AbstractInstanceType<T>>,
		true
	> {
		const listEndpoint = this.list();
		const endpoint = this.create().extend({
			schema: listEndpoint.schema,
			url: (params: ImageParams & { [k: string]: any }) => this.listUrl(params),
			method: "PUT"
		});

		return endpoint.extend({
			fetch: async (params: ImageParams, body: File[] = []) => {
				const formdata = new FormData();

				for (const [i, file] of body.filter(isNotNull).entries()) {
					formdata.append(`images[${i}]`, file, file.name);
				}

				await endpoint.fetch(params, formdata);

				return await listEndpoint.fetch(params);
			}
		});
	}

	static async fetch(input: RequestInfo, init: RequestInit) {
		// perform actual network request getting back json
		const jsonResponse = await super.fetch(input, init);

		if (jsonResponse && typeof jsonResponse.map === "function") {
			return jsonResponse.map(mapImageUrlToResource);
		}

		if (typeof jsonResponse === "string") {
			return mapImageUrlToResource(jsonResponse);
		}

		return mapImageUrlToResource(typeof input === "string" ? input : input.url);
	}
}
