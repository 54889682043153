import React from "react";

import { TemplatePageProps, LayoutPage } from "../../../types";
import { addRegisteredLayout, PanelSettings } from "../layoutDescriptions";
import { Grid, ThemeProvider } from "@material-ui/core";
import { Panel, PanelProps } from "../../../panel";
import { TemplatePanel } from "@ploy-lib/types";
import { useStyles, actionsTheme } from "./styles";
import { useSubmitErrorSnackbar } from "../../../hooks/useSubmitErrorSnackbar";
import FormStatus from "../../../components/FormStatus";
import clsx from "clsx";

export type FullWidthPanels = {
	main: TemplatePanel;
	actions: TemplatePanel;
};
export type FullWidthPanelKeys = keyof FullWidthPanels;
export type FullWidthPage = LayoutPage<FullWidthPanelKeys, FullWidthPanels>;

const mainPanelSettings: PanelSettings = {
	editableOptions: {
		title: false,
		elevation: true,
		square: true,
		desktopViewForMobile: true
	}
};

const panelSettings: Record<FullWidthPanelKeys, PanelSettings> = {
	main: mainPanelSettings,
	actions: {}
};

addRegisteredLayout({
	name: "FullWidth",
	displayName: "FullWidth",
	panels: panelSettings,
	canDecideMaxWidth: true,
	canSetBackgroundColor: true
});

export interface FullWidthProps extends TemplatePageProps<FullWidthPage> {
	backgroundColor?: string;
}

export const FullWidth = (props: FullWidthProps) => {
	const { panels, maxWidth } = props;
	const { main, actions } = panels;

	const classes = useStyles(props);
	useSubmitErrorSnackbar();

	const pageDefinedPanelSpecificProps: Partial<PanelProps> = {
		maxWidth
	};

	return (
		<Grid
			id="fullWidthWrapper"
			container
			spacing={3}
			justifyContent="center"
			className={clsx(classes.pageMaxWidth, classes.background)}
		>
			<Grid item xs={12} md={12}>
				{main && <Panel {...pageDefinedPanelSpecificProps} {...main} />}
			</Grid>
			{actions?.sections && (
				<Grid item xs={12} md={12}>
					<FormStatus className={classes.alignRight} hideStatusMessage />
					<ThemeProvider theme={actionsTheme}>
						<Panel
							transparent
							justifyContent="center"
							{...pageDefinedPanelSpecificProps}
							{...actions}
						/>
					</ThemeProvider>
				</Grid>
			)}
		</Grid>
	);
};

FullWidth.displayName = "DployFormFullWidth";
