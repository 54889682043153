import { legacyApiResourceUrl } from "@ploy-lib/core";
import { BaseCamelCasedResource } from "./BasePlainResource";
import paramsToString from "../resources/BaseResource";
import {
	ID,
	ApplicationStatusId,
	ApplicationStatus,
	AppLoad,
	ClientSettings,
	DocumentStatusId,
	DocumentStatus,
	EsignType,
	CustomerRole
} from "@ploy-lib/types";
import {
	Resource,
	SimpleResource,
	EndpointExtraOptions
} from "@rest-hooks/rest";

export class AppLoadResource extends BaseCamelCasedResource implements AppLoad {
	readonly id?: string;
	readonly applicationNumber: string;
	readonly isDirectUser: boolean;
	readonly productExternalCode: string;
	readonly productInfoHeader: string;
	readonly esignType: EsignType;
	readonly productName = "";
	readonly productTypeId: ID;
	readonly applicationStatusId: ApplicationStatusId =
		ApplicationStatusId.NotSet;
	readonly isEsigned: boolean;
	readonly applicationStatus: ApplicationStatus = ApplicationStatus.NotSet;
	readonly applicationId: number;
	readonly documentStatusId: DocumentStatusId = DocumentStatusId.NotSet;
	readonly documentStatus: DocumentStatus = DocumentStatus.NotSet;
	readonly applicationStatusDescription = "";
	readonly documentStatusDescription = "";
	readonly vendorInfo?: {
		contactPhone: string;
		contactEmail: string;
		fullName: string;
		salesChannel: string;
	};
	readonly coSignerId?: number;
	readonly loggedInCustomerRole: CustomerRole;
	readonly vulcanContext: string;
	readonly settings: ClientSettings = {
		esignType: EsignType.SsnRequired,
		ssnServiceEnabled: false,
		canChooseSigners: false,
		canAddAndDeleteVendorSigners: false,
		canEditIndividualDocumentsForSigners: false,
		disableVendorSigner: false,
		hideVendorSigner: false,
		disableSupplierSigner: false,
		hideSupplierSigner: false,
		disableSignerEditing: false,
		externalUserCanNotAddSigners: false,
		allowEmptyEmail: false,
		showValidPeriod: false,
		canChangeObject: false,
		distributionValues: {},
		alwaysShowProductName: false,
		mustSignDefaultChecked: false,
		showProductInfo: false,
		requireRegnrForEsign: false,
		signersMustHaveContactInfo: false,
		hideSigningActions: false,
		hideDocumentsToSign: false,
		showLastUpdateTime: false,
		useStandardLayoutForDirectUsers: false,
		useIndividualSignerStatus: false
	};
	readonly isScored: boolean;
	readonly externalRedirectUrl: string;
	readonly sentToEsignature: string;
	readonly isSentToEsignatureByInternal: boolean;
	readonly equityStatus: number;
	readonly productId: number;

	pk() {
		return `${this.id ?? this.applicationNumber}`;
	}

	static getEndpointExtra(): EndpointExtraOptions {
		return {
			...super.getEndpointExtra(),
			invalidIfStale: true,
			dataExpiryLength: Infinity
		};
	}

	static url<T extends typeof SimpleResource>(
		this: T,
		urlParams: Readonly<Record<string, any>>
	) {
		const { applicationNumber, ...searchParams } = urlParams;

		const url = super.url(urlParams);

		return `${url}${paramsToString(searchParams)}`;
	}

	static urlRoot = legacyApiResourceUrl("AppLoad/LoadApplication");
}

export interface MinimalInitialData {
	customerNumber?: string;
	customerTypeId?: number;
	objectCondition?: number;
	objectTypeId?: number;
	objectSubTypeId?: number;
}

export class AppInitResource extends BaseCamelCasedResource implements AppLoad {
	readonly id?: string;
	readonly applicationNumber?: string;
	readonly productExternalCode: string;
	readonly productInfoHeader: string;
	readonly productName = "";
	readonly productTypeId: ID;
	readonly applicationStatusId: ApplicationStatusId =
		ApplicationStatusId.NotSet;
	readonly isEsigned: boolean;
	readonly applicationStatus: ApplicationStatus = ApplicationStatus.NotSet;
	readonly applicationId: number;
	readonly documentStatusId: DocumentStatusId = DocumentStatusId.NotSet;
	readonly documentStatus: DocumentStatus = DocumentStatus.NotSet;
	readonly documentStatusDescription = "";
	readonly applicationStatusDescription = "";
	readonly vendorInfo?: {
		contactPhone: string;
		contactEmail: string;
		fullName: string;
		salesChannel: string;
	};
	readonly coSignerId?: number;
	readonly loggedInCustomerRole: CustomerRole;
	readonly vulcanContext: string;
	readonly settings: ClientSettings;
	readonly isScored: boolean;
	readonly externalRedirectUrl: string;
	readonly sentToEsignature: string;
	readonly isSentToEsignatureByInternal: boolean;
	readonly equityStatus: number;
	readonly productId: number;

	pk() {
		return `${this.id ?? this.productExternalCode}`;
	}

	static getEndpointExtra(): EndpointExtraOptions {
		return {
			...super.getEndpointExtra(),
			invalidIfStale: true,
			dataExpiryLength: Infinity
		};
	}

	static url<T extends typeof SimpleResource>(
		this: T,
		urlParams: Readonly<Record<string, any>> = {}
	) {
		const { productExternalCode, ...searchParams } = urlParams;

		const url = super.url(urlParams);

		return `${url}${paramsToString(searchParams)}`;
	}

	static init<T extends typeof Resource>(this: T) {
		const instanceFetch = this.fetch.bind(this);
		return this.detail().extend({
			method: "POST",
			fetch(
				this: any,
				paramsAndBody: Readonly<{ initialData?: MinimalInitialData }>
			) {
				const { initialData: body, ...params } = paramsAndBody;
				return instanceFetch(this.url(params), this.getFetchInit(body));
			}
		});
	}

	static urlRoot = legacyApiResourceUrl("AppLoad/InitProductApplication");
}
