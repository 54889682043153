import { LimitDetail, LimitDetailEntity } from "@ploy-lib/rest-resources";
import * as Yup from "yup";
import { FieldType, TableColumnMetadata } from "./types";

export class LimitDetailTableSchema extends LimitDetailEntity {
	static dployTesterContext: string = "LimitDetails";
	static isReadOnlyRow = (rowData: LimitDetail, cells: any) =>
		rowData?.engagementCustomerId &&
		rowData.engagementCustomerId !== rowData.customerId;
	static metaData: Partial<
		Record<keyof LimitDetail, TableColumnMetadata<LimitDetail>>
	> = {
		customerId: {
			fieldType: FieldType.Select,
			isRequired: true,
			displayValue: (
				value: any,
				rowData: LimitDetail,
				_cells: any,
				isPlaceholder: boolean,
				firstInGroup: boolean
			) => {
				// Placeholder is true if row is grouped into another row
				if (!isPlaceholder || firstInGroup) {
					return value;
				}
				// If row has subCustomers that can draw from row, display value
				if ((rowData.customers?.length ?? 0) > 1) return value;
				// If engagement row, display value, else hide value
				return rowData.engagementCustomerId &&
					rowData.engagementCustomerId !== rowData.customerId
					? rowData.customerName ?? value
					: "";
			}
		},
		products: {
			fieldType: FieldType.Multiselect,
			isRequired: true
		},
		objectTypes: {
			fieldType: FieldType.Multiselect,
			isRequired: true
		},
		limitAmount: {
			fieldType: FieldType.Currency,
			isRequired: true,
			validatorSchemas: {
				Min1: Yup.number().min(1)
			}
		},
		usedAmount: {
			fieldType: FieldType.Currency
		},
		minEquity: {
			fieldType: FieldType.Percent,
			minimumFractionDigits: 0,
			maximumFractionDigits: 2,
			isRequired: true,
			validatorSchemas: {
				Min0Max100: Yup.number().min(0).max(100)
			}
		},
		loanAmount: {
			fieldType: FieldType.Currency
		},
		remainingAmount: {
			fieldType: FieldType.Currency
		},
		objectCondition: {
			fieldType: FieldType.Select,
			isRequired: true
		},
		terms: {
			fieldType: FieldType.Number,
			isRequired: true
		},
		vatRule: {
			fieldType: FieldType.Select,
			isEditableFunctions: {
				EditableIfLoan: (form, cells) =>
					// Consider refactoring optionValues to be list of objects instead of key value pairs.
					// This validation for example, should perform a check on the product type, not the name, witch is not possible atm
					cells
						.find(cell => cell.column.id === "products")
						?.column?.optionValues?.some(
							o =>
								o.value.includes("lån") &&
								form.values.products?.some(p => p.toString() === o.key)
						) || false
			},
			displayValue: (value, rowData, cells) =>
				cells
					.find(cell => cell.column.id === "products")
					?.column?.optionValues?.some(
						o =>
							o.value.includes("lån") &&
							rowData.products?.some(p => p.toString() === o.key)
					)
					? value
					: "",
			defaultValue: "1" //Ved oppstart
		},
		pd: {
			fieldType: FieldType.Percent,
			aggregate: tableRows => {
				return tableRows[0]?.pd ?? 0;
			},
			aggregateFooter: tableRows => {
				return tableRows[0]?.mainCustomerPd ?? 0;
			}
		},
		lgd: {
			fieldType: FieldType.Percent,
			aggregate: tableRows => {
				return (
					(tableRows?.reduce((acc, row) => acc + (row?.lgdAmount ?? 0), 0) /
						tableRows?.reduce(
							(acc, row) =>
								acc + (row?.usedAmount ?? 0) + (row?.remainingAmount ?? 0),
							0
						)) *
					100
				);
			}
		},
		lgdAmount: {
			fieldType: FieldType.Currency
		},
		normalizedLoss: {
			fieldType: FieldType.Percent,
			aggregate: tableRows =>
				((LimitDetailTableSchema.metaData.pd?.aggregate?.(tableRows) ?? 0) /
					100) *
				((LimitDetailTableSchema.metaData.lgd?.aggregate?.(tableRows) ?? 0) /
					100) *
				100,
			aggregateFooter: tableRows =>
				((LimitDetailTableSchema.metaData.pd?.aggregateFooter?.(tableRows) ??
					0) /
					100) *
				((LimitDetailTableSchema.metaData.lgd?.aggregate?.(tableRows) ?? 0) /
					100) *
				100
		},
		depotValue: {
			fieldType: FieldType.Currency
		},
		usedDepotValue: {
			fieldType: FieldType.Currency
		},
		depotValueRate: {
			fieldType: FieldType.Percent,
			minimumFractionDigits: 0
		},
		toDate: {
			fieldType: FieldType.Date
		}
	};
}
