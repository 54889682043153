import { ApplicationDebtResource } from "@ploy-lib/rest-resources";
import { ApplicationDebt, ApplicationDebtSource } from "@ploy-lib/types";
import { v4 as uuid } from "uuid";
import * as Yup from "yup";
import { FieldType, TableColumnMetadata } from "./types";

export class ApplicationDebtSchema extends ApplicationDebtResource {
	static dployTesterContext: string = "ApplicationDebt";

	static metaData: Partial<Record<keyof ApplicationDebt, TableColumnMetadata>> =
		{
			customerId: { fieldType: FieldType.Select, isRequired: true },
			amount: { fieldType: FieldType.Currency, isRequired: true },
			originalAmount: { fieldType: FieldType.Currency },
			loanType: { fieldType: FieldType.Select, isRequired: true },
			refinance: { fieldType: FieldType.Checkbox },
			comment: { fieldType: FieldType.Text },
			source: {
				fieldType: FieldType.Select,
				defaultValue: ApplicationDebtSource.Applicant
			},
			creditLimit: { fieldType: FieldType.Currency },
			lender: { fieldType: FieldType.Text },
			loanNumber: { fieldType: FieldType.Text },
			nominalInterest: { fieldType: FieldType.Percent },
			refinanceAccount: { fieldType: FieldType.Text },
			refinanceAmount: { fieldType: FieldType.Currency },
			termAmount: { fieldType: FieldType.Currency },
			maturity: { fieldType: FieldType.Number }
		};
}

export class LimitedApplicationDebtSchema extends ApplicationDebtResource {
	static dployTesterContext: string = "LimitedApplicationDebt";

	static isDeletableRow = (rowData: any, cells: any) =>
		rowData["source"] === ApplicationDebtSource.Applicant;

	static metaData: Partial<
		Record<
			keyof (ApplicationDebt & { _frontendOnlyId?: string }),
			TableColumnMetadata
		>
	> = {
		_frontendOnlyId: {
			fieldType: FieldType.Text,
			defaultValueFunc: () => uuid()
		},
		customerId: {
			fieldType: FieldType.Select,
			isRequired: true,
			isEditableFunctions: {
				isEditable: (form, row) => {
					const source = row.find(c => c.column.id === "source")?.value as
						| ApplicationDebtSource
						| undefined;

					return source === ApplicationDebtSource.Applicant;
				}
			}
		},
		amount: { fieldType: FieldType.Currency, isRequired: true },
		originalAmount: { fieldType: FieldType.Currency },
		loanType: { fieldType: FieldType.Select, isRequired: true },
		refinance: { fieldType: FieldType.Checkbox },
		comment: { fieldType: FieldType.Text },
		source: {
			fieldType: FieldType.Select,
			defaultValue: ApplicationDebtSource.Applicant
		},
		lender: { fieldType: FieldType.Text },
		loanNumber: { fieldType: FieldType.Text }
	};
}

export class LimitedRefinancedApplicationDebtSchema extends ApplicationDebtResource {
	static dployTesterContext: string = "LimitedRefinancedApplicationDebt";

	static isDeletableRow = (rowData: any, cells: any) =>
		rowData["source"] === ApplicationDebtSource.Applicant;

	static metaData: Partial<Record<keyof ApplicationDebt, TableColumnMetadata>> =
		{
			customerId: { fieldType: FieldType.Select, isRequired: true },
			amount: { fieldType: FieldType.Currency, isRequired: true },
			originalAmount: { fieldType: FieldType.Currency },
			loanType: { fieldType: FieldType.Select, isRequired: true },
			comment: { fieldType: FieldType.Text },
			source: { fieldType: FieldType.Select },
			lender: { fieldType: FieldType.Text, isRequired: true },
			loanNumber: { fieldType: FieldType.Text, isRequired: true },
			refinanceAccount: { fieldType: FieldType.Text, isRequired: true },
			refinanceAmount: {
				fieldType: FieldType.Currency,
				isRequired: true,
				validatorSchemas: {
					notLargerThanAmount: Yup.number()
						.min(0)
						.when(
							["amount"],
							(values: any[], schema: Yup.NumberSchema, options: any) => {
								let [amount] = values as [number];
								return schema.max(amount);
							}
						)
				}
			}
		};
}
