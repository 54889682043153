import React, { useMemo, useEffect, useCallback } from "react";
import {
	Variable,
	Service,
	Resolve,
	Validator,
	TemplateField,
	AppLoad,
	CalculationResponse
} from "@ploy-lib/types";
import {
	DispatchProvider,
	CalculationProvider,
	ServiceManagerProvider,
	useEventManager
} from "../context";
import { Namespaced, FunctionTypes } from "../../calculator";
import { createValidationHelpers } from "@ploy-lib/validation-helpers";
import { useCalculation } from "./useCalculation";
import { useServiceManager } from "./useServiceManager";
import { useGeneratedCalcRules } from "./useGeneratedCalcRules";
import { ServiceBodyField } from "../../types";
import {
	useFormikContext,
	FormikProvider,
	FormikContextType,
	FormikErrors
} from "formik";
import { IUtils } from "@date-io/core/IUtils";
import { DispatchPriority } from "./useRenderReducer";
import { ServiceBodyValue } from "../..";

export interface CalculationManagerProps<
	TN extends string,
	TD extends string | number | boolean
> {
	cgfMap?: Record<string, TemplateField>;
	calcRules?: Partial<Record<TN, string>>;
	variables?: Record<TN, Variable[]>;
	services?: Record<TN, Service[]>;
	validators?: Record<TN, Validator[]>;
	macros?: Namespaced<number, TN>;
	resolves?: Record<string, Resolve<TN>>;
	serviceBodyFields?: Partial<Record<TN, ServiceBodyField[]>>;
	additionalServiceBody?: Partial<Record<TN, ServiceBodyValue[]>>;
	children: React.ReactNode;
	initialValues?: Partial<Namespaced<TD, TN>>;
	formValues?: Partial<Namespaced<TD, TN>>;
	initialWriteLocked?: Partial<Namespaced<boolean | null, TN>>;
	initialVisible?: Partial<Namespaced<boolean, TN>>;
	functions?: FunctionTypes[];
	namespaces?: TN[];
	clear?: TN[];
	locale: string;
	dateUtils: IUtils<any>;
	onSubmitError?: (values: any) => void;
	fieldsWithIgnoreCgfHandling?: Record<string, Record<string, boolean>>;
	appData?: AppLoad | null;
	onUpdateDataModel?: (calc: CalculationResponse) => void;
}
const emptyObject: any = {};
const emptyArray = [];

export const CalculationManager = <
	TN extends string,
	TD extends string | number | boolean
>({
	cgfMap = emptyObject,
	children,
	formValues,
	initialWriteLocked,
	initialVisible,
	initialValues,
	calcRules = emptyObject,
	services = emptyObject,
	variables = emptyObject,
	validators = emptyObject,
	resolves = emptyObject,
	macros = emptyObject,
	functions = emptyArray,
	namespaces,
	clear,
	serviceBodyFields = emptyObject,
	additionalServiceBody,
	locale,
	dateUtils,
	onUpdateDataModel,
	appData
}: CalculationManagerProps<TN, TD>) => {
	const formikbag = useFormikContext();

	const evaluatedGeneratedCalcRules = useGeneratedCalcRules<TD, TN>(calcRules);

	const validationHelpers = useMemo(
		() => createValidationHelpers(locale, dateUtils),
		[locale, dateUtils]
	);

	const [{ calculation, serviceTriggers }, dispatch] = useCalculation(
		cgfMap,
		evaluatedGeneratedCalcRules,
		validationHelpers,
		variables,
		validators,
		functions,
		services,
		resolves,
		macros,
		formValues,
		initialWriteLocked,
		initialVisible,
		initialValues,
		serviceBodyFields,
		namespaces,
		clear
	);

	const eventManager = useEventManager<TN, TD>();

	const onServiceSucces = useCallback(
		(namespace: TN, service: Service) => {
			eventManager.serviceSuccess.notify({ namespace, service });
		},
		[eventManager.serviceSuccess]
	);

	const serviceManager = useServiceManager(
		services,
		additionalServiceBody,
		calculation,
		serviceTriggers,
		dispatch,
		onUpdateDataModel,
		onServiceSucces
	);

	if (process.env.NODE_ENV === "development") {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		useEffect(() => {
			(window as any).__dploy = {
				...(window as any).__dploy,
				calculation,
				serviceManager,
				dispatch
			};
		}, [calculation, serviceManager, dispatch]);
	}

	useEffect(() => {
		eventManager.patch.subscribe(({ patches }) => {
			for (const p of patches) {
				dispatch(
					{
						type: "patch",
						payload: {
							patches: [p]
						}
					},
					p.noDefer ? DispatchPriority.Normal : DispatchPriority.Defer
				);
			}
		});
	}, [dispatch, eventManager.patch]);

	useEffect(() => {
		if (appData && appData.vulcanContext === "SubmitApplication")
			eventManager.calculatorReady.notify();
	}, [appData, eventManager.calculatorReady]);

	// Override formik errors with our own
	const calcrulesErrors = calculation.errors as FormikErrors<any>;
	const errors = Object.assign({}, calcrulesErrors, formikbag.errors);

	const formikbagWithErrors: FormikContextType<any> = useMemo(
		() => ({ ...formikbag, errors }),
		[formikbag, errors]
	);

	return (
		<FormikProvider value={formikbagWithErrors}>
			<CalculationProvider value={calculation}>
				<DispatchProvider value={dispatch}>
					<ServiceManagerProvider value={serviceManager}>
						{children}
					</ServiceManagerProvider>
				</DispatchProvider>
			</CalculationProvider>
		</FormikProvider>
	);
};
