import { FormikProps } from "formik";
import * as Yup from "yup";

import { CellProps, Row } from "react-table";

export enum FieldType {
	Select,
	Multiselect,
	Text,
	Number,
	Currency,
	Percent,
	Date,
	Checkbox
}

export const standardValidation: {
	[key in FieldType]: Yup.AnySchema;
} = {
	[FieldType.Checkbox]: Yup.boolean().nullable(),
	[FieldType.Select]: Yup.string().nullable(),
	[FieldType.Multiselect]: Yup.array().nullable(),
	[FieldType.Text]: Yup.string().nullable(),
	[FieldType.Number]: Yup.number()
		.transform(value => (isNaN(value) ? undefined : value))
		.nullable(),
	[FieldType.Currency]: Yup.number().nullable(),
	[FieldType.Percent]: Yup.number().nullable(),
	[FieldType.Date]: Yup.date().nullable()
} as const;

export const requiredValidation: {
	[key in FieldType]: Yup.AnySchema;
} = {
	[FieldType.Checkbox]: Yup.boolean().required(),
	[FieldType.Select]: Yup.string().required(),
	[FieldType.Multiselect]: Yup.array().required().min(1),
	[FieldType.Text]: Yup.string().required(),
	[FieldType.Number]: Yup.number().required(),
	[FieldType.Currency]: Yup.number().required(),
	[FieldType.Percent]: Yup.number().required(),
	[FieldType.Date]: Yup.date().required()
};

export interface TableColumnMetadata<RowData extends {} = {}> {
	readonly fieldType: FieldType;
	// Defaults to 2 for percent and 0 for number/currency
	readonly minimumFractionDigits?: number;
	// Defaults to 2 for percent and 0 for number/currency
	readonly maximumFractionDigits?: number;
	readonly allowNegative?: boolean;
	readonly isRequired?: Boolean;
	readonly displayValue?: (
		value: CellProps<RowData>["value"],
		rowData: Row<RowData>["original"],
		cells: Row<RowData>["cells"],
		isPlaceholder: boolean,
		firstInGroup: boolean
	) => any;
	readonly alwaysEditable?: Boolean;
	readonly isEditableFunctions?: Record<
		string,
		(form: FormikProps<RowData>, row: Row<RowData>["cells"]) => boolean
	>;
	readonly validatorSchemas?: Record<string, Yup.AnySchema>;
	readonly aggregate?: (tableRows: RowData[]) => number;
	readonly aggregateFooter?: (tableRows: RowData[]) => number;
	readonly defaultValue?: unknown;
	readonly defaultValueFunc?: () => unknown;
}
