import { legacyApiResourceUrl } from "@ploy-lib/core";
import { BaseCamelCasedResource } from "./BasePlainResource";

export class DocumentSettingsResource extends BaseCamelCasedResource {
	readonly allowedFileTypes: string[] = [];

	pk() {
		return "Settings";
	}

	static urlRoot = legacyApiResourceUrl("DocumentGrid");
}
